import { useState, useEffect } from "react";
import useStateRef from "../../utility/useStateRef";
import checkUndefined from "../../utility/checkUndefined";
import MyCard from "../../styled/card";
import timeGap from "../../utility/timeGap";
import moment from "moment";
import { formatFloat } from "../../utility/formatFloat";
import ProductionLineChart from "./chart/ProductionLineChart";
import LineChart from "./chart/LineChart";
import LabellineChart from "./chart/LabelLineChart";
import Title from "../../components/Custom/Title";

import { Row, Col, Select, message, DatePicker } from "antd";
import {
  MyComponent,
  MySubtitleH3,
  MyComponentThree,
} from "../../styled/publicTag";
import {
  IconWon,
  IconPn,
  IconPdtNM,
  IconPlanQty,
  IconOpenT,
  IconMhc,
  IconNgC,
  IconOkc,
  IconAct,
  IconPpe,
  IconQty,
  IconTar,
} from "../../components/Icon";
import { getWsWl, getProductionInfo } from "../../services/api";

const timeModal = {
  UUID: "",
  wsID: "",
  wlID: "",
  woN: "",
  wotype: 0,
  wpcset: null,
  wpcSN: "",
  addSN: 0,
  prefix: 0,
  suffix: 0,
  offfix: 0,
  woMix: null,
  lineMix: 0,
  PN: "",
  pdtNM: null,
  planHead: 0,
  openT: null,
  updT: "0001-01-01T00:00:00Z",
  situF: null,
  situFT: null,
  lifeF: null,
  lifeFT: "0001-01-01T00:00:00Z",
  planQty: 0,
  d_planQty: 0,
  inC: 0,
  d_inC: 0,
  ngC: 0,
  okC: 0,
  moldCavity: 0,
  hdC: 0,
  whC: 0,
  mhC: 0,
  hourC: 0,
  ACT: 0,
  PPE: 0,
  TAR: 0,
  QTY: 0,
};

const ProductionInfo = () => {
  const { Option } = Select;

  const [singleWpc, setSingleWpc, singleWpcRef] = useStateRef({
    isOpen: false,
    thisWpc: {},
    wpcChartInfo: timeModal,
    timeData: [],
    ng: [],
    wlID: "",
    time: moment(new Date()).format('YYYY-MM-DD'),
  });

  const [allStation, setAllStation] = useState({
    options: [],
    data: [],
  });



  useEffect(() => {
    const call_getWsWl = async () => {
      try {
        const res = await getWsWl();
        if (res.status) {
          let wls = [];
          for (const x of res.data) {
            wls = [...wls, ...x.wlIDs];
          }
          const wlOptions = wls.map((x) => {
            return (
              <Option key={x.wlID} value={x.wlID}>
                {`${x.wlID} - ${x.wlNM}`}
              </Option>
            );
          });
          setAllStation({ ...allStation, options: wlOptions, data: wls });
          console.log("wls[0].wlID", wls[0].wlID);
          setSingleWpc({ ...singleWpcRef.current, wlID: wls[0].wlID });
        }
      } catch (e) {
        console.log(e);
      }
    };
    call_getWsWl();
  }, []);

  useEffect(() => {
    const call_getProductionInfo = async () => {
      const res = await getProductionInfo({ wlID: singleWpc.wlID, time: singleWpc.time });
      console.log({ wlID: singleWpc.wlID, time: singleWpc.time })
      if (res.status) {
        //篩選出最新那筆資料
        let newData = [];
        //加上時間
        let timeData = [];
        for (const [i, x] of res.data.sections.entries()) {
          // 算qty
          const qty = x.planQty - x.prefix - x.suffix;
          //、最後一筆ＵＵＩＤ不為空的為singleWpc
          if (x.UUID !== "") {
            newData.push({ ...x, qty: qty });
          }

          //圖表時間軸計算
          let time = "";
          if (i + 8 < 24) {
            time = `${i + 8 < 10 ? "0" + (i + 8) : i + 8}:00-${i + 9 < 10 ? "0" + (i + 9) : i + 9
              }:00`;
          } else {
            time = `${i - 16 < 10 ? "0" + (i - 16) : i - 16}:00-${i - 15 < 10 ? "0" + (i - 15) : i - 15
              }:00`;
          }
          timeData.push({ ...x, time: time, qty: qty + 0 });
        }

        //ngData = 不良品圓餅圖計算
        let ngData = [];
        if (res.data.ng.length > 0) {
          console.log("ngData: ", res.data.ng);
          ngData = res.data.ng.map((x) => {
            return { ...x, percent: formatFloat(x.percent, 2) };
          });
        }

        setSingleWpc({
          ...singleWpcRef.current,
          wpcChartInfo: newData[newData.length - 1]
            ? newData[newData.length - 1]
            : timeModal,
          timeData: timeData,
          ngData: ngData,
        });
        message.success(res.msg);
      } else {
        setSingleWpc({
          ...singleWpcRef.current,
          wpcChartInfo: timeModal,
          timeData: {},
          ngData: [],
        });
        message.error(res.msg);
      }
    };
    if (singleWpc.wlID !== "") {
      call_getProductionInfo();
    }
  }, [singleWpc.wlID, singleWpc.time]);

  return (
    <>
      <Title title="設備生產狀況">
        <div>
          <Select
            style={{ width: "180px", height: "38px", marginRight: '15px' }}
            size="large"
            value={singleWpcRef.current.wlID}
            onChange={(e) => {
              setSingleWpc({ ...singleWpc, wlID: e });
            }}
          >
            {allStation.options}
          </Select>
          <DatePicker
            placeholder='請選擇日期'
            onChange={value => {
              setSingleWpc({ ...singleWpc, time: value.format("YYYY-MM-DD") })
            }}
            size='large'
            allowClear={false}
            style={{ width: '150px', marginRight: '15px' }}
            value={moment(singleWpc.time)}
          />
          {/* <SituFlight light={singleWpc.wpcChartInfo.situF} /> */}
        </div>
      </Title>
      <Row justify="space-between" align="middle" gutter={[24, 12]}>
        <Col span={24}>
          <MyComponent>
            <Row justify="space-around" align="middle" gutter={[24, 12]}>
              <Col span={6}>
                <MyCard
                  title="工單號"
                  align="left"
                  content={checkUndefined(singleWpc.wpcChartInfo.woN)}
                  icon={<IconWon />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="料號"
                  align="left"
                  content={checkUndefined(singleWpc.wpcChartInfo.PN)}
                  icon={<IconPn />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="品名"
                  align="left"
                  content={checkUndefined(singleWpc.wpcChartInfo.pdtNM)}
                  icon={<IconPdtNM />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="目標數量"
                  align="left"
                  content={singleWpc.wpcChartInfo.qty}
                  icon={<IconPlanQty />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="開工時間"
                  align="left"
                  content={
                    singleWpc.wpcChartInfo.openT
                      ? moment(singleWpc.wpcChartInfo.openT).format(
                        "YYYY-MM-DD HH:mm"
                      )
                      : checkUndefined(singleWpc.wpcChartInfo.openT)
                  }
                  icon={<IconOpenT />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="生產時間"
                  align="left"
                  content={
                    singleWpc.wpcChartInfo.openT
                      ? timeGap(
                        new Date(moment()),
                        new Date(singleWpc.wpcChartInfo.openT)
                      )
                      : "暫無數據"
                  }
                  icon={<IconMhc />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="不良數量"
                  align="left"
                  content={singleWpc.wpcChartInfo.ngC}
                  icon={<IconNgC />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="產出數量"
                  align="left"
                  content={singleWpc.wpcChartInfo.okC}
                  icon={<IconOkc />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="稼動率"
                  content={<LineChart percent={singleWpc.wpcChartInfo.ACT} />}
                  icon={<IconAct />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="生產效率"
                  content={<LineChart percent={singleWpc.wpcChartInfo.PPE} />}
                  icon={<IconPpe />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="直通良率"
                  content={<LineChart percent={singleWpc.wpcChartInfo.QTY} />}
                  icon={<IconQty />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="目標達成率"
                  content={<LineChart percent={singleWpc.wpcChartInfo.TAR} />}
                  icon={<IconTar />}
                />
              </Col>
            </Row>
          </MyComponent>
        </Col>
        <Col span={12}>
          <MyComponent>
            <MySubtitleH3>生產趨勢</MySubtitleH3>
            <MyComponentThree>
              <ProductionLineChart
                chartData={singleWpc.timeData}
                infoData={singleWpc.wpcChartInfo}
              />
            </MyComponentThree>
          </MyComponent>
        </Col>
        <Col span={12}>
          <MyComponent>
            <MySubtitleH3>不良分佈</MySubtitleH3>
            <MyComponentThree>
              <LabellineChart data={singleWpc.ngData} />
            </MyComponentThree>
          </MyComponent>
        </Col>
      </Row>
    </>
  );
};
export default ProductionInfo;
