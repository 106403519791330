import React from 'react';
import styled from 'styled-components';

import { Row, Col } from 'antd';

import { fontSize } from '../../styled/publicTag';

import LineChart from './LineChart';
import WaveChart from './WaveChart';
//import FacetChart from './FacetChart';
import OvalChart from './OvalChart';
import BarChart from './BarChart';
import Title from '../../components/Custom/Title';

const Wrapper = styled.div`
  padding: 0px 20px 10px 25px;
  background-color: ${({ theme }) => theme.mainBg};

  // 折線圖跟水波圖的區域
  .ChartContainer {
    display: flex;
    justify-content: space-between;
  }

  // 稼動
  .FacetChart {
    //background-color: red;
    margin-top: 20px;
  }

  // 稼動率的區塊
  .FacetChartContainer {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    //height: 50px;
    margin-top: 20px;
    display: flex;
  }

  // 稼動率的 header title
  .colHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fontSize.p};
    color: #3089c7;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // 線別的按鈕
  .wsBtn {
    color: rgba(0, 0, 0, 0.87);
    //width: 60px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0;
    padding: 6px 16px;
  }
`

const OverView = () => {
  
  return (
    <Wrapper>
      <Title title="綜合效能總表">
        <div></div>
      </Title>
      <div className='ChartContainer'>
        <LineChart width='75%' height='350px' />
        <WaveChart width='24%' height='350px' />
      </div>
      {/* <FacetChart /> */}
      <div className='FacetChart'>
        {/* <div className='FacetChartHeader'> */}
          <div className='FacetChartContainer'>
            <div className='colHeader' style={{width: '10%'}}>線別</div>
            <div className='colHeader' style={{width: '25%'}}>稼動率</div>
            <div className='colHeader' style={{width: '22.5%'}}>直通良率</div>
            <div className='colHeader' style={{width: '22.5%'}}>生產效率</div>
            <div className='colHeader' style={{width: '20%'}}>綜合效能</div>
          </div>

          <div className='FacetChartContainer'>
            <div className='colHeader' style={{width: '10%'}}>
              <div className='wsBtn'>D01</div>
            </div>
            <div className='col' style={{width: '25%'}}>
              <BarChart width={90} />
            </div>
            <div className='col' style={{width: '22.5%'}}>
              <BarChart width={100} />
            </div>
            <div className='col' style={{width: '22.5%'}}>
              <BarChart width={30} />
            </div>
            <div className='col' style={{width: '20%'}}>
              <OvalChart percentage={100} />
            </div>
          </div>

          <div className='FacetChartContainer'>
            <div className='colHeader' style={{width: '10%'}}>
              <div className='wsBtn'>D01</div>
            </div>
            <div className='col' style={{width: '25%'}}>
              <BarChart width={80} />
            </div>
            <div className='col' style={{width: '22.5%'}}>
              <BarChart width={40} />
            </div>
            <div className='col' style={{width: '22.5%'}}>
              <BarChart width={99} />
            </div>
            <div className='col' style={{width: '20%'}}>
              <OvalChart percentage={20} />
            </div>
          </div>

          <div className='FacetChartContainer'>
            <div className='colHeader' style={{width: '10%'}}>
              <div className='wsBtn'>D01</div>
            </div>
            <div className='col' style={{width: '25%'}}>
              <BarChart width={85} />
            </div>
            <div className='col' style={{width: '22.5%'}}>
              <BarChart width={12} />
            </div>
            <div className='col' style={{width: '22.5%'}}>
              <BarChart width={50} />
            </div>
            <div className='col' style={{width: '20%'}}>
              <OvalChart percentage={80} />
            </div>
          </div>
        </div>
      {/* </div> */}
    </Wrapper>
  )
}

export default OverView;