import React from "react";
//import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
//import { CSSTransition, TransitionGroup } from "react-transition-group";
import Users from "../pages/Users";
import SystemLogin from "../pages/SystemLogin";
import DashBoard from "../pages/DashBoard";
import DashBoardCard from "../pages/DashBoardCard";
import AbcBoard from "../pages/AbcBoard";
import WosPermissions from "../pages/WosPermissions";
import BasicProductInfo from "../pages/BasicProductInfo";
import PSdata from "../pages/PSdata";
import Station from "../pages/Station";
import Line from "../pages/Line";
import Mould from "../pages/Mould";
import ProductWpc from "../pages/ProductWpc";
import OverView from "../pages/OverView";
import OverViewLines from "../pages/OverViewLines";
import WorkStations from "../pages/WorkStations";
import WorkResume from "../pages/WorkResume";
import WorkInProduction from "../pages/WorkInProduction";
import WorkPassStations from "../pages/WorkPassStations";
import WorkWaybill from "../pages/WorkWaybill";
import ApsManagement from "../pages/ApsManagement";
import Department from "../pages/Department";
import JobTitle from "../pages/JobTitle";
import ProductionInfo from "../pages/ProductionInfo";
import BadProject from "../pages/BadProject";
import GoodProject from "../pages/GoodProject";
import StopProject from "../pages/StopProject";
import WareHouse from "../pages/WareHouse";
import TestSVG from "../pages/TestSVG";
import FunctionProject from "../pages/FunctionProject";
import Companys from "../pages/Companys";
import OemInfo from "../pages/OemInfo";
import Basic from "../pages/Basic";
//WMS
import PlainIn from "../pages/WMS/PlanIn";

const Routes = () => {
  let location = useLocation();

  const r_userState = useSelector((allReducers) => allReducers.userState);
  //console.log('r_userState = ', r_userState)

  return (
    <>
      {Object.keys(r_userState).length > 0 ? (
        <Switch location={location}>

          {/* 不需權限也可以看的 */}
          <Route path="/dashboard" component={DashBoard} />
          <Route path="/dashboardCard" component={DashBoardCard} />
          <Route path="/abcboard" component={AbcBoard} />
          <Route path="/OverViewLines" component={OverViewLines} />
          <Route path="/ProductionInfo" component={ProductionInfo} />
          
          {/* demo 系統只有superman可以看到 */}
          {r_userState.pdsID === "superman" && (
            <Route path="/WorkStations" component={WorkStations} />
          )}
          {r_userState.pdsID === "superman" && (
            <Route path="/WareHouse" component={WareHouse} />
          )}
          {r_userState.pdsID === "superman" && (
            <Route path="/WorkResume" component={WorkResume} />
          )}
          {r_userState.pdsID === "superman" && (
            <Route path="/WorkInProduction" component={WorkInProduction} />
          )}
          {r_userState.pdsID === "superman" && (
            <Route path="/WorkPassStations" component={WorkPassStations} />
          )}
          {r_userState.pdsID === "superman" && (
            <Route path="/abcboard" component={AbcBoard} />
          )}
          {r_userState.pdsID === "superman" && (
            <Route path="/OverView" component={OverView} />
          )}
          {r_userState.pdsID === "superman" && (
            <Route path="/WorkWaybill" component={WorkWaybill} />
          )}
          {r_userState.pdsID === "superman" && (
            <Route path="/Basic" component={Basic} />
          )}
           {r_userState.pdsID === "superman" && (
            <Route path="/TestSVG" component={TestSVG} />
          )}
          {/* {r_userState.pdsID === "superman" && (
             <>
              
              
              
              
              
              
              
              
              
            </>
          )} */}
         
          <Redirect exact from="*" to="/dashboard" />
        </Switch>
      ) : null}
    </>
  );
};
export default Routes;
