import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Table } from 'antd';

import { fontSize, MySubtitleH4 } from '../../styled/publicTag';
import { Input , Button, message } from 'antd';

import { PlusOutlined, ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { getOEM, deleteOEM, getOEMsupp, getOEMItem } from '../../services/api';

import ModalContainer from './ModalContainer'

import {
  IconTrash,
  IconPen
} from '../../components/Icon';

import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';


message.config({
  duration: 1.5
})

const OemInfo = () => {

  const innerWidth = window.innerWidth;

  const [ s_tableData, set_s_tableData ] = useState([]);
  const [ is_showModal, set_is_showModal ] = useState(false);  // 是否開啟 dialog
  const [ s_editData, set_s_editData ] = useState({});

  const [ s_OEM , set_s_OEM ] = useState([]); // 廠商基本資料
  const [ s_OEMitem , set_s_OEMitem ] = useState([]); // 廠商基本資料

  const [ tableLoading, setTableLoading ] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [ s_searchValue, set_s_searchValue ] = useState('');    // 要搜尋的文字

  const [ pageInfo, setPageInfo ] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 建立新的委外基本資料
  const createPS = () => {
    set_is_showModal(true);
  }

  // 搜尋委外基本資料
  const onSearch = (value, event) => {
    
    const call_getOEM = async (value) => {
      
      if(value === '') return;
      
      const res = await getOEM({vendorID: value});
      
      if(res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        set_s_tableData([])
        message.error(res.msg);
      }

    }
    call_getOEM(value);
  }

  //編輯委外基本資料
  const editOEM = rowData => {
    set_is_showModal(true);
    set_s_editData(rowData);
  }

  // 刪除委外基本資料
  const removeOEM = rowData => {
    Modal.confirm({
      title: '委外資料刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的委外資料無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {

        const res = await deleteOEM({vendorID: rowData.vendorID});
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getOEM();
      },
      cancelText: '取消',
    });
  }

  // 取得所有委外基本資料
  const call_getOEM = async () => {
    try {
      const res = s_searchValue === '' ? await getOEM() : await getOEM({vendorID: s_searchValue});
      console.log('res = ', res)
      if(res.status === true) {
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      } else {
        set_s_tableData([])
      }
      
      
    } catch (e) {
      console.log('---------- api call_getOEM error ----------');
      console.log(e)
    }
  }

  // 取得所有車間資料
  useEffect(() => { 
    
    if(is_showModal === false) {
      call_getOEM();
      set_s_editData({})
    }
  }, [is_showModal]);

  // 取得所有外包項目、廠商資料(UI 顯示用)
  useEffect(() => {
    // 取得所有廠商基本資料
    const call_getOEMsupp = async () => {
      try {
        const res = await getOEMsupp();
        console.log('res = ', res)
        res.status === true ? set_s_OEM(res.data) : set_s_OEM([]);
        
      } catch (e) {
        console.log('---------- api call_getOEM error ----------');
        console.log(e)
      }
    }

    // 取得所有外包項目資料
    const call_getAllOemitem = async () => {
      try {
        const res = await getOEMItem();
        console.log('ress = ', res)
        res.status === true ? set_s_OEMitem(res.data) : set_s_OEMitem([]);
        
      } catch (e) {
        console.log('---------- api getAllOemitem error ----------');
        console.log(e)
      }
    }

    call_getOEMsupp();
    call_getAllOemitem();
  }, []);

  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value)
    if(value === '') {
      try {
        const res = await getOEM();
        console.log(res.data)
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      } catch (e) {
        console.log('---------- api getOEM error ----------');
        console.log(e)
      }
    }
  }

  const tableColumns = [
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${300}px` : `${300}px`,
    },
    {
      title: '外包項目',
      dataIndex: 'oemitem',
      key: 'oemitem',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${200}px`,
      render: (text) => {
        try {
          return s_OEMitem.length > 0 ? (
            s_OEMitem.find(item => item.oemitem === text).oemNM
          ) : text
        } catch (e) {
          return text
        }
      }
    },
    {
      title: '廠商編號',
      dataIndex: 'suppID',
      key: 'suppID',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
      render: (text) => {
        try {
          return s_OEM.length > 0 ? (
            s_OEM.find(item => item.suppID === text).suppNM
          ) : text
        } catch {
          return text
        }
      }
    },
    {
      title: '外包代碼',
      dataIndex: 'vendorID',
      key: 'vendorID',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${350}px`
    },
    {
      title: '報價項號',
      dataIndex: 'quotN',
      key: 'quotN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`
    },
    {
      title: '是否有效',
      dataIndex: 'isvalid',
      key: 'isvalid',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            // style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {text === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : text === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '供商註記',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      // width: innerWidth <= 1440 ? `${1200}px` : `${200}px`
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <IconPen className='icon' onClick={() => editOEM(rowData)} />
          <IconTrash className='icon' onClick={() => removeOEM(rowData)} />
        </div>
      )
    },
  ]
  return (
    <>
      <Title 
        title='委外資料管理'
        placeholder='請輸入欲查詢委外資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createPS}
      />
      <CustomTable 
        scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {is_showModal ? (
        <CustomModal 
          width='60%' 
          title={Object.keys(s_editData).length > 0 ? "修改委外基本資料" : "新建委外基本資料"} 
          visible={is_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_is_showModal(false)}
          // afterClose={() => {
          //   set_s_PSData({});
          //   // call_getAllPS();
          // }}
        >
          <ModalContainer set_is_showModal={set_is_showModal} s_editData={s_editData} set_s_editData={set_s_editData} />
        </CustomModal>
       ) : null}
      
    </>
  )
}

export default OemInfo;