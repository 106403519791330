import styled from "styled-components";


const LightElement = ({ className, color, size="18px",disabled=false }) => {



  return (
    <span className={className}>
     <span className={`ant-badge-status-dot ${disabled ? "my-disabled" : "ant-badge-status-processing"}`} />
    </span>
  )
};

/**
 * @param {string} type "main , error , warning , success" 
 * @param {string} size "default 18px , input any px"
 * @param {Bool} disabled - description
*/

const colorMap = {
  'W': 'white',
  'Y': '#ffd700',
  'R': 'red',
  'B': 'blue',
  'G': 'rgb(54, 230, 28)'
}

const Light = styled(LightElement)`
  .ant-badge-status-dot {
    position: relative;
    width: ${props=>props.size};
    height: ${props=>props.size};
    background-color: ${({color}) => colorMap[color]};
  }
  .ant-badge-status-processing {
    top: -1px;
    display: inline-block;
    width: ${props=>props.size};
    height: ${props=>props.size};
    vertical-align: middle;
    border-radius: 50%;
  }
  .ant-badge-status-processing:after {
    background-color: ${({color}) => colorMap[color]};
    border: ${({color}) => colorMap[color]};
  }
`;

export default Light