import React, { useState } from 'react';
import { message } from 'antd';

import HD from './HD';
import TB from './TB';

message.config({
  duration: 1.5
});

export const DataContext = React.createContext();

const Basic = () => {
  
  const [ c_lightData, set_c_lightData ] = useState({});   // 要亮起來的資料

  return (
    <DataContext.Provider value={{c_lightData, set_c_lightData}}>
      <div style={{height: 'calc(100vh - 110px)'}}>
        <div style={{height: '50%'}}>
          <HD />
        </div>
        <div style={{height: '50%'}}>
          {Object.keys(c_lightData).length > 0 ? <TB /> : null}
        </div>
      </div>
    </DataContext.Provider>
     
  )
};

export default Basic;