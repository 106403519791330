import React, { useState, useEffect, useContext } from 'react';

import { getOkItem, deleteOkItem } from '../../../services/api';
import { MySubtitleH4 } from '../../../styled/publicTag';
import { message, Modal } from 'antd';

import { DataContext } from '../index';

import {  ExclamationCircleOutlined } from '@ant-design/icons';

import {
  IconTrash,
  IconPen
} from '../../../components/Icon';

import CustomTable from '../../../components/antdCustom/CustomTable';

message.config({
  duration: 1.5
})

const GoodProject = ({ s_searchValue, s_showModal }) => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);

  const { c_showModal, set_c_showModal, set_c_editData, c_searchValue } = useContext(DataContext);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 取得所有良品項目資料
  const call_getAllGoodProject = async () => {
    try {
      const res = s_searchValue === '' ? await getOkItem() : await getOkItem({item: s_searchValue});
      console.log('res = ', res)
      if(res.data) {
        console.log('全部良品資料 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      }
      
    } catch (e) {
      console.log('---------- api getNgItem error ----------');
      console.log(e)
    }
  }

  // 取得所有良品項目資料
  useEffect(() => { 
    call_getAllGoodProject();
  }, [c_showModal]);

  // 編輯良品資料
  const editGoodProject = rowData => {
    set_c_showModal(true);
    set_c_editData(rowData)
  };

  // 刪除良品資料
  const removeGoodProject = rowData => {
    Modal.confirm({
      title: '良品項目資料刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的良品項目資料無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteOkItem(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllGoodProject();
      },
      cancelText: '取消',
    });
  }

  const tableColumns = [
    {
      title: '項目ID',
      dataIndex: 'item',
      key: 'item',
      align: 'center',
      // width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    },
    {
      title: '項目名稱',
      dataIndex: 'itemNM',
      key: 'itemNM',
      align: 'center',
      // width: innerWidth <= 1440 ? `${200}px` : `${250}px`
    },
    {
      title: '註記',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <div style={{display: 'flex'}}>
            <IconPen className='icon' onClick={() => editGoodProject(rowData)} />
            <IconTrash className='icon' onClick={() => removeGoodProject(rowData)} />
          </div>
        </div>
      )
    },
  ]

  return (
    <div>
      <CustomTable 
        scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
    </div>
  )
};

export default GoodProject;