import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const HeaderStyle = styled.div`
  display: 'flex';
  align-items: 'center';

  .styleButton {
    border-radius: 5px;
    margin-left: 15px;
  }
`

const Header = (props) => {

  const { s_searchData, handleChange, handleClick } = props;

  return (
    <HeaderStyle>
      <DatePicker.RangePicker 
        size='large'
        allowClear={false}

        value={[moment(s_searchData.startDate), moment(s_searchData.endDate)]}
        onChange={(dates, dateStrings) => {
          handleChange('time', dateStrings)
        }}
      />
      <Button 
        onClick={handleClick} 
        type="primary" 
        className='styleButton'
        icon={<PlusOutlined />} 
        size='large'
      >
        新增
      </Button>
    </HeaderStyle>
  );
};

export default Header;
