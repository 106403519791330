import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, Select, Radio, Divider, message } from 'antd';
import { addWh, modifyWh } from '../../../services/api';

message.config({
  duration: 1.5
})

const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_s_showModal, s_AllPSdata, s_WhData}) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields()
  }, [form, s_WhData]);

  const onSubmit = async values => {

    const type = Object.keys(s_WhData).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      // 新增倉別資料
      // alert('wait api')
      const res = await addWh(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };

    if(type === 'modify') {
      // 修改倉別資料
      // alert('wait api')
      const res = await modifyWh(values);
      console.log('modify res = ', res)
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false);
    };
    
    
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  return (
    <>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          whID: Object.keys(s_WhData).length > 0 ? s_WhData.whID : '',
          psID: Object.keys(s_WhData).length > 0 ? s_WhData.psID : null,
          whNM: Object.keys(s_WhData).length > 0 ? s_WhData.whNM : '', 
          whType: Object.keys(s_WhData).length > 0 ? s_WhData.whType : null, 
          note: Object.keys(s_WhData).length > 0 ? s_WhData.note : '', 
        }}
      >
        <Row>
          <Col span={8}>
            <Form.Item 
              //labelCol={{offset:2}} 
              wrapperCol={{span:22}}
              label={<label style={{color: '#6C6C6C'}}>倉別ID</label>}
              name="whID"
              rules={Object.keys(s_WhData).length === 0 ? [{ required: true, message: '請填寫倉別ID!' }] : null}
            >
                <Input disabled={Object.keys(s_WhData).length === 0 ? false : true} name='whID' placeholder="請填寫倉別ID" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:22, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>倉別名稱</label>}
              name="whNM"
              rules={[{ required: true, message: '請填寫倉別名稱!' }]}
            >
              <Input name='whNM' placeholder="請填寫倉別名稱" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              // className="customItem" 
              labelCol={{offset:2}} 
              wrapperCol={{span:22, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>倉別型態</label>} 
              name="whType" 
              rules={[{ required: true, message: '請選擇倉庫型態!' }]}
            >
              <Select placeholder="選擇倉庫型態" allowClear >
                <Select.Option value='mct'>原料倉</Select.Option>
                <Select.Option value='hpdt'>半成品倉</Select.Option>
                <Select.Option value='pdt'>成品倉</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item 
              // className="customItem" 
              // labelCol={{offset:2}} 
              wrapperCol={{span:22}}
              label={<label style={{color: '#6C6C6C'}}>車間</label>} 
              name="psID" 
              rules={[{ required: true, message: '請選擇車間!' }]}
            >
              <Select placeholder="選擇車間" allowClear >
                {s_AllPSdata.length > 0 && s_AllPSdata.map(item => {
                  return <Select.Option key={item.psID} value={item.psID}>{item.psNM}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} >
            <Form.Item 
              // labelCol={{offset:2}} 
              wrapperCol={{span:24}}
              label={<label style={{color: '#6C6C6C'}}>註記</label>}
              // rules={[{ required: true, message: '請填寫車間註記!' }]}
              name="note"
            >
              <Input.TextArea 
                rows={4} 
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};
export default ModalContainer