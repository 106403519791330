import React, { useState, useEffect, useRef, useContext } from 'react';
import { message, Modal } from 'antd';

import { CloseCircleOutlined, CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';

import { MySubtitleH4 } from '../../../styled/publicTag.js';
import {
  IconTrash,
  IconPen
} from '../../../components/Icon';

import { DataContext } from '../index';

import { getWpcHd, deleteWpcHd } from '../../../services/api';

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';
import CustomModal from '../../../components/antdCustom/CustomModal';

import ModalContainer from './ModalContainer';

const innerWidth = window.innerWidth;

const HD = () => {

  let countRef = useRef(null);

  const [scrollY, setScrollY] = useState("")
  const [ s_showModal, set_s_showModal ] = useState(false);
  const [ s_editData, set_s_editData ] = useState({});

  const [ s_tableData, set_s_tableData ] = useState([]);

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const { c_lightData, set_c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 即時計算table高度
  const getTableScroll = ({ extraHeight, ref } = {}) => {
    if (typeof extraHeight == "undefined") {
      //  默认底部分页64 + 边距10
      extraHeight = 64
    }
    let tHeader = null
    if (ref && ref.current) {
      tHeader = ref.current.getElementsByClassName("ant-table-thead")[0]
    } else {
      tHeader = document.getElementsByClassName("ant-table-thead")[0]
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0
    if (tHeader) {
      tHeaderBottom = tHeader.getBoundingClientRect().bottom
    }
    // 窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    let tmpvh = Object.keys(c_lightData).length > 0 ? '50vh' : '100vh'

    let height = `calc(${tmpvh} - ${tHeaderBottom + extraHeight}px)`
    // 空数据的时候表格高度保持不变,暂无数据提示文本图片居中
    if (ref && ref.current) {
      let placeholder = ref.current.getElementsByClassName('ant-table-placeholder')[0]
      if (placeholder) {
        placeholder.style.height = height
        placeholder.style.display = "flex"
        placeholder.style.alignItems = "center"
        placeholder.style.justifyContent = "center"
      }
    }
    console.log('height = ', height)
    return height
  }

  // 搜尋不良項目資料
  const onSearch = (value, event) => {
    const call_getWpcHd = async (value) => {

      // alert(value)
      // return
      
      if(value === '') return;
      
      const res = await getWpcHd({PN: value});
      console.log('search res = ', res)
      
      if(res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }

    }
    call_getWpcHd(value);
  }

  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value)
    if(value === '') {
      try {
        const res = await getWpcHd();
        console.log('res = ', res)
        if(res.data) {
          console.log('全部不良品資料 = ', res.data);
          const tmpData = res.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          });
          set_s_tableData(tmpData)
        }
        
      } catch (e) {
        console.log('---------- api getNgItem error ----------');
        console.log(e)
      }
    }
  }

  // 新增產品途程資料
  const createWpc = () => {
    set_s_showModal(true);
  }

  // 編輯產品途程資料
  const editWpc = rowData => {
    set_s_editData(rowData);
    set_s_showModal(true);
  }

  const call_getWpcHd = async () => {
    const res = await getWpcHd();
    console.log('getWpcHd res => ', res)
    res.status === true ? set_s_tableData(res.data) : set_s_tableData([]);
  };

  // 刪除產品途程資料
  const deleteWpc = rowData => {
    Modal.confirm({
      title: '產品途程資料刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的產品途程資料無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteWpcHd(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getWpcHd();
      },
      cancelText: '取消',
    });
  }

  const tableColumns = [
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`,
    },
    {
      title: '途程組',
      dataIndex: 'wpcset',
      key: 'wpcset',
      align: 'center',
      width: innerWidth <= 1440 ? `${130}px` : `${150}px`,
    },
    {
      title: '品名',
      dataIndex: 'pdtNM',
      key: 'pdtNM',
      align: 'center',
      // width: innerWidth <= 1440 ? `${300}px` : `${400}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { textAlign: 'left' }
          },
          children: (
            <div>
              {text}
            </div>
          )
        };
      }
    },
    {
      title: '是否有效',// 是否重工途程
      dataIndex: 'isvalid',
      key: 'isvalid',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
      render: (text, rowData, index) => {
        return {
          children: (
            <div className="checkIcon">
             {text === true ? <CheckCircleFilled className='successIcon' /> : text === false ? <CloseCircleOutlined style={{color: 'gray'}} /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '途程組說明',
      dataIndex: 'wpcsetNM',
      key: 'wpcsetNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`,
    },
    {
      title: '途程組備註',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`,
    },
    {
      title: '更新時間',
      dataIndex: 'updtTstr',
      key: 'updtTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <div style={{display: 'flex'}}>
            <IconPen onClick={() => editWpc(rowData)} className='icon' />
            <IconTrash onClick={() => deleteWpc(rowData)} className='icon' /> 
          </div>
        </div>
      )
    }
  ]

  // 取得表頭資料
  useEffect(() => {

    
    call_getWpcHd();

  }, [s_showModal]);

  // 表身有資料時計算高度
  useEffect(() => {
    setScrollY(getTableScroll({ ref: countRef }))
  }, [c_lightData]);

  return (
    <>
      <Title 
        title='產品途程資料'
        placeholder='請輸入欲查詢的途程料號'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createWpc}
      />

      <CustomTable 
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ x: 'max-content', y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        rowClassName={(record) => {
          const rowKey = record.PN + record.wpcset;
          const dataKey = c_lightData.PN + c_lightData.wpcset;
          return rowKey === dataKey ? "clickRowStyle" : "";
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              const rowKey = record.PN + record.wpcset;
              const dataKey = c_lightData.PN + c_lightData.wpcset;
              if (rowKey === dataKey) {
                set_c_lightData({})
              } else {
                set_c_lightData(record)
              }
            }
          }
        }}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {s_showModal ? (
        <CustomModal 
          width='50%' 
          title={ Object.keys(s_editData).length === 0 ? "新增產品途程資料" : "修改產品途程資料" } 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_editData={s_editData} set_s_editData={set_s_editData} />
        </CustomModal>
      ) : null}

    </>
  )
};

export default HD;