import React, { useState, useEffect } from 'react';

import { IconTrash, IconPen } from '../../components/Icon';

import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { MySubtitleH4 } from '../../styled/publicTag';

import { deleteOEMsupp } from '../../services/api';

import Title from '../../components/Custom/Title';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';

import ModalContainer from './ModalContainer';

import { getOEMsupp } from '../../services/api';

message.config({ duration: 1.5 });

const Companys = () => {

  const innerWidth = window.innerWidth;

  const [is_showModal, set_is_showModal] = useState(false); // 是否開啟 dialog
  const [s_tableData, set_s_tableData] = useState([]);       // 全部廠商基本資料

  const [ s_editData, set_s_editData ] = useState({}); // 要被編輯的資料

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 建立新的廠商資料
  const createCompany = () => {
    set_is_showModal(true);
  }

  const handleChange = async value => {
    set_s_searchValue(value);

    if(value === '') {

      try {
        setTableLoading(true);
        //取得所有廠商基本資料
        const res = await getOEMsupp();
        //console.log(res)
        if(res.status === true) {
         set_s_tableData(res.data);
        }
        setTableLoading(false);
      } catch (e) {
        console.log("全部員工基本資料-資料獲取失敗", e);
      }
    }
  }

  // 搜尋廠商基本資料
  const onSearch = (value, event) => {
    
    const call_getOEMsupp = async () => {
      
      if(s_searchValue === '') return;
      console.log('s_searchValue = ', s_searchValue)
      const res = await getOEMsupp({'suppID': s_searchValue});
      console.log('res = ', res)
      res.status === true ? set_s_tableData(res.data) : set_s_tableData([]);

    }

    
    call_getOEMsupp(s_searchValue);
  }

  // 編輯廠商基本資料
  const editCompany = rowData => {
    set_is_showModal(true);
    set_s_editData(rowData);
  }

  // 刪除廠商基本資料
  const removeCompany = rowData => {
    Modal.confirm({
      title: '廠商資料刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的廠商無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteOEMsupp(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllCompany();
      },
      cancelText: '取消',
    });
  }

  //table 要有哪些欄位
  const tableColumns = [
    {
      title: '廠商編號',
      dataIndex: 'suppID',
      key: 'suppID',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${200}px`
      //...getColumnSearchProps('staffID'),
    },
    {
      title: '廠商名稱',
      dataIndex: 'suppNM',
      key: 'suppNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${200}px`
      //...getColumnSearchProps('peopleNM'),
    },
    {
      title: '供應類別',
      dataIndex: 'supptype',
      key: 'supptype',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
      render: (text, rowData, index) => {

        const supptypeMap = {
          'oem': '委外加工',
          'matl': '原料',
          'part1': '自用零件',
          'part2': '市售零件'
        }

        return supptypeMap[text]
      }
    },
    // {
    //   title: '類別名稱',
    //   dataIndex: 'typeNM',
    //   key: 'typeNM',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${120}px` : `${200}px`
    //   //...getColumnSearchProps('cardID'),
    // },
    {
      title: '備註',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      // width: innerWidth <= 1440 ? `${120}px` : `${120*1.6}px`
      //...getColumnSearchProps('cardID'),
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <div style={{display: 'flex'}}>
            <IconPen className='icon' onClick={() => editCompany(rowData)} />
            <IconTrash className='icon' onClick={() => removeCompany(rowData)} />
          </div>
        </div>
      )
    },
  ];

  // 取得所有廠商資料
  const call_getAllCompany = async () => {
    
    const res = await getOEMsupp();
    console.log('res = ', res)
    res.status === true ? set_s_tableData(res.data) : set_s_tableData([]);
  }

  // 第一次渲染取得所有廠商資料
  useEffect(() => {

    call_getAllCompany();

  }, []);

  // 每次關閉 Modal 都重新取得所有廠商資料
  useEffect(() => {

    if(is_showModal === false) {
      call_getAllCompany();
    }

  }, [is_showModal]);

  return (
    <>
      <Title 
        title='廠商基本資料'
        placeholder='請輸入欲查詢廠商資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createCompany}
      />

      <CustomTable 
        loading={tableLoading}
        scroll={{ x: "max-content",y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.length > 0 ? s_tableData.map((item, index) => {
          return { ...item, key: index }
        }) : null}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {is_showModal ? ( 
        <CustomModal 
          width='60%' 
          title={Object.keys(s_editData).length > 0 ? "修改廠商 資料管理" : "新建廠商資料管理"} 
          visible={is_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_is_showModal(false)}
        >
          <ModalContainer set_is_showModal={set_is_showModal} s_editData={s_editData} set_s_editData={set_s_editData} />
        </CustomModal>
      ) : null}

    </>
  )
};

export default Companys;