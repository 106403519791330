/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import Routes from "../../routes/";
import { useSelector, useDispatch } from "react-redux";
import { setuserStateReducer } from '../../redux/action'

import { useHistory, useParams } from "react-router-dom";

import { Layout, message } from "antd";

import webOutTime from "../../utility/webOutTime"

import { ThemeProvider } from "styled-components";
import { themeLight, themeDark } from "../../styled/theme";

import MyHeader from "../../components/Header/Header";
import NavBar from "../../components/NavBar/NavBar";
import MyFooter from "../../components/Footer/Footer";

import Cookies from "universal-cookie";

import { getUserPerm } from "../../services/api";

const Home1 = () => {

  const [ stateA, set_stateA ] = useState(0);

  useEffect(() => {
    console.log('first render')
  }, []);

  useEffect(() => {
    console.log('first render & 每次畫面更新')
  });

  useEffect(() => {
    console.log('first render & 每次 stateA 更新')
  }, [stateA]);

  useEffect(() => {
    
    console.log('line 42')
    
    return () => {
      console.log('line 43 first render 不執行 & 每次 stateA 更新後執行完成才執行 line 42')
    }

  }, [stateA]);

  useEffect(() => {

    return () => {
      console.log('line 44 只在組建卸載時執行')
    }
  }, []);

  const aa = '123'

  return (
    <div>
      <button onClick={() => set_stateA(stateA + 1)}>+1</button>
    </div>
  )
}

function Home() {
  const dispatch = useDispatch();

  const history = useHistory();

  const cookies = new Cookies();

  const r_isNavbar = useSelector(allReducers => allReducers.navBar);
  const r_isNavbarInline = useSelector(allReducers => allReducers.navBarInline);
  const r_theme = useSelector(allReducers => allReducers.theme);

  useEffect(() => {
    const timeID = setInterval(() => {
      webOutTime()
      // if (!webOutTime()){
      //   // window.location.reload()
      //   window.location.assign(window.location.href);//刷新當前頁
      // }
    }, 1000);

    return () => {
      clearInterval(timeID)
    }
  }, []);

  //用資料驅動 NavBar.jsx 的畫面
  const [s_navBarData, set_s_navBarData] = useState(null);

  const [user, setUser] = useState({
    userName: "",
    dept: "",
  });

  // 透過 json 渲染 navBar 畫面(icon 還沒)
  useEffect(() => {
    const Data = {
      navBarArray:[
        {
          hasSub: true,
          subItems: [
            {
              hasSub: false,
              iconName: 'SketchOutlined',
              componentName: 'EnterRule',
              content: '入庫規劃'
            },
            {
              hasSub: false,
              iconName: 'SketchOutlined',
              componentName: 'OutRule',
              content: '出庫規劃'
            },
            {
              hasSub: false,
              iconName: 'SketchOutlined',
              componentName: 'ExceedRule',
              content: '越庫規劃'
            },
            {
              hasSub: false,
              iconName: 'SketchOutlined',
              componentName: 'StockCheck',
              content: '庫存盤點'
            },
            {
              hasSub: false,
              iconName: 'SketchOutlined',
              componentName: 'StockChange',
              content: '庫存異動'
            },
            {
              hasSub: false,
              iconName: 'SketchOutlined',
              componentName: 'BackupChange',
              content: '庫儲異動'
            },
            {
              hasSub: false,
              iconName: 'SketchOutlined',
              componentName: 'DepotStockSearch',
              content: '庫存查詢'
            }
          ],
          iconName: 'ExportOutlined',
          componentName: 'DepotManagement',
          keyName: 'DepotManagement',
          content: '倉儲管理系統'
        },
        {
          hasSub: true,
          subItems: [
            {
              hasSub: false,
              iconName: 'SketchOutlined',
              componentName: 'OrderStockSearch',
              content: '庫存查詢'
            },
            {
              hasSub: false,
              iconName: 'SketchOutlined',
              componentName: 'GoodsSell',
              content: '銷售發貨'
            }
          ],
          iconName: 'PicLeftOutlined',
          componentName: 'OrderManagement',
          keyName: 'OrderManagement',
          content: '訂單管理系統'
        }
      ]
    };
   // set_s_navBarData(Data);
  }, []);


  //call 所有初始API
  useEffect(() => {
    // const wsID = cookies.get("wsID");

    // const wlID = cookies.get("wlID");

    // if (!wsID && !wlID) {
    //   history.push("/login");
    //   message.error("請重新登入");
    // }

    call_initPage();
  }, []);

  const call_initPage = async () => {
    try {
      const res = await getUserPerm();
      //console.log('res = ', res)
      if (res.status) {
        cookies.set("userName", res.data.user);
        dispatch(setuserStateReducer(res.data))
        setUser({ userName: res.data.peopleNM });
      } else {
        console.log("initPage error !!!")
        console.log(res)
        message.error(res.msg);
      }
    } catch (e) {
      console.log("call_initPage", e);
    }
  };

  const nabar_temporary_margin_width = (isNavbar, isNavbarInline) => {
    //打開狀態
    if (!isNavbar) {
      return 200;
    } else {
      return isNavbarInline ? 0 : 80;
    }
  };

  const layoutStyle = {
    marginTop: "64px",
    marginLeft: nabar_temporary_margin_width(r_isNavbar, r_isNavbarInline),
    transition: ".3s",
    minHeight: "calc(100vh - 112px)",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
  return (
    <ThemeProvider theme={r_theme ? themeLight : themeDark}>
      <div className="App" style={{height: '100vh', backgroundColor: 'pink'}}>
        <Layout style={{height: '100vh'}}>
          <NavBar user={user} s_navBarData={s_navBarData} />
          <MyHeader user={user} />
          <Layout style={layoutStyle}>
            <div style={{ padding: "0px 15px" }}>
              <Routes />
            </div>
            <div>
              <Layout.Footer style={{paddingLeft: '0px', paddingBottom: '10px', marginLeft: '25px'}}>
                <MyFooter />
              </Layout.Footer>
            </div>
          </Layout>
        </Layout>
        {/* <MyFooter /> */}
      </div>
    </ThemeProvider>
  );
}

export default Home;
