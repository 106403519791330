import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, Select, Radio, Divider, message } from 'antd';
import { modifyOEM, addOEM, getAllProduct, getOEMsupp, getOEMItem } from '../../../services/api';

const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_is_showModal, s_editData, set_s_editData}) => {

  const [form] = Form.useForm();

  const [s_AllProduct, set_s_AllProduct] = useState([]);
  const [ s_OEM, set_s_OEM] = useState([]); // 廠商下拉選單
  const [ s_OEMitem, set_s_OEMitem] = useState([]); // 外包下拉選單

  const [ s_oldVendorID, set_s_oldVendorID ] = useState(''); // 更新時要傳舊的 PK 給後端

  useEffect(() => {
    form.resetFields();

    set_s_oldVendorID(s_editData.vendorID);

  }, [form, s_editData]);
// alert(2)
  useEffect(() => {
    // 取得所有廠商基本資料
    const call_getOEMsupp = async () => {
      try {
        const res = await getOEMsupp();

        res.status === true ? set_s_OEM(res.data) : set_s_OEM([]);
        
      } catch (e) {
        console.log('---------- api call_getOEM error ----------');
        console.log(e)
      }
    }

    // 取得所有外包項目資料
    const call_getAllOemitem = async () => {
      try {
        const res = await getOEMItem();
        console.log('ress = ', res)
        res.status === true ? set_s_OEMitem(res.data) : set_s_OEMitem([]);
        
      } catch (e) {
        console.log('---------- api getAllOemitem error ----------');
        console.log(e)
      }
    }

    call_getOEMsupp();
    call_getAllOemitem();
  }, []);

  const onSubmit = async values => {

    const type = Object.keys(s_editData).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      // 新增委外基本資料
      const res = await addOEM(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_is_showModal(false);
    };

    if(type === 'modify') {
      // 修改委外基本資料
      const res = await modifyOEM({ ...values, oldVendorID: s_oldVendorID });
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_is_showModal(false);
    };
    
    
  }

  // 取得全部產品基本資料(做料號下拉選單用)
  useEffect(() => {
   const call_getAllProduct = async () => {
      try {
        const res = await getAllProduct();
        if(res.data) {
          set_s_AllProduct(res.data);
          console.log('全部產品基本資料 = ', res.data)
          //message.success(res.msg);
        }
      } catch (e) {
        console.log("全部產品基本資料-資料獲取失敗", e);
        message.error('資料獲取失敗');
      } 
    };

    call_getAllProduct();
  }, [])

  const handleCancel = () => {
    set_is_showModal(false)
  }

  return (
    <Form.Provider
      onFormChange={(formName, info) => {
        console.log('aaa')
        const { changedFields } = info;
        console.log(changedFields)

        if(changedFields[0].name[0] === 'PN' || changedFields[0].name[0] === 'oemitem' || changedFields[0].name[0] === 'suppID') {
          let PN = form.getFieldValue("PN");
          let oemitem = form.getFieldValue("oemitem");
          let suppID = form.getFieldValue("suppID");

          if(PN === undefined || oemitem === undefined || suppID === undefined) {
            form.setFieldsValue({
              'vendorID': ''
            });
          } else {
            form.setFieldsValue({
              'vendorID': PN + oemitem + suppID
            });
          }
        
      }}}
      >
    
    <StyledForm
      onFinish={onSubmit}
      name="basic"
      form={form}
      autoComplete="off"
      layout="vertical"
      initialValues={{
        PN: Object.keys(s_editData).length > 0 ? s_editData.PN : undefined,
        oemitem: Object.keys(s_editData).length > 0 ? s_editData.oemitem : undefined,
        suppID: Object.keys(s_editData).length > 0 ? s_editData.suppID : undefined,
        vendorID: Object.keys(s_editData).length > 0 ? s_editData.vendorID : '',
        quotN: Object.keys(s_editData).length > 0 ? s_editData.quotN : '',
        note: Object.keys(s_editData).length > 0 ? s_editData.note : '',
        isvalid: Object.keys(s_editData).length > 0 ? s_editData.isvalid : '',
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>料號</label>}
            name="PN"
            rules={Object.keys(s_editData).length === 0 ? [{ required: true, message: '請選擇料號!' }] : null}
          >
            <Select
              disabled={Object.keys(s_editData).length > 0 ? true : false}
              placeholder="請選擇料號"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {s_AllProduct && s_AllProduct.map((item, index) => {
                return <Select.Option key={index} value={item.PN}>{item.PN}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        
        <Col span={8}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>外包項目</label>}
            name="oemitem"
            rules={[{ required: true, message: '請選擇外包項目!' }]}
          >
            {/* <Input name='oemitem' placeholder="請填寫外包項目" /> */}
            <Select placeholder="請選擇外包項目" allowClear>
              {s_OEMitem && s_OEMitem.map((item, index) => {
                return <Select.Option key={index} value={item.oemitem}>{item.oemNM}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>廠商編號</label>}
            name="suppID"
            rules={[{ required: true, message: '請選擇廠商編號!' }]}
          >
            {/* <Input name='suppID' placeholder="請填寫廠商編號" /> */}
            <Select placeholder="請選擇廠商編號" allowClear>
              {s_OEM && s_OEM.map((item, index) => {
                return <Select.Option key={index} value={item.suppID}>{item.suppNM}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>外包代碼</label>}
            name="vendorID"
            // rules={[{ required: true, message: '請填寫報價項號!' }]}
          >
            <Input disabled name='vendorID' />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>報價項號</label>}
            name="quotN"
            // rules={[{ required: true, message: '請填寫報價項號!' }]}
          >
            <Input name='quotN' placeholder="請填寫報價項號" />
          </Form.Item>
        </Col>
        <Col span={8} >
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>是否有效</label>}
            rules={[{ required: true, message: '請填寫外包類別!' }]}
            name="isvalid"
          >
            <Radio.Group name="isvalid">
              <Radio value='Y'>是</Radio>
              <Radio value='N'>否</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} >
          <Form.Item 
            // labelCol={{offset:2}} 
            wrapperCol={{span:24}}
            label={<label style={{color: '#6C6C6C'}}>供商註記</label>}
            name="note"
          >
            <Input.TextArea 
              rows={4} 
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider style={{margin: '6px 0px 12px 0px'}} />

      <Row>
        <Col offset={12} span={12}>
          <div style={{display: 'flex',justifyContent: 'flex-end'}}>
            <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
              取消
            </Button>
            <Form.Item>
              <Button size='large' type="primary" htmlType="submit" style={{ borderRadius: '5px'}}>
                確定
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>

    </StyledForm>
    </Form.Provider>
  )
};

export default ModalContainer;