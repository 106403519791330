import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import './ModalContainer.css'
import moment from 'moment'

import { getAllStation, addMold, modifyMold, getAllProduct } from '../../../services/api';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { Form, Button, Input, Row, Col, Select, DatePicker, Radio, message, Divider, InputNumber } from 'antd';

const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`

  .ant-input, .ant-picker, .ant-input-number {
    border-radius: 5px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_s_showModal, s_MouldData, set_s_MouldData, call_getAllMould}) => {

  const [form] = Form.useForm();

  const [s_AllStation, set_s_AllStation] = useState([]);

  const [s_editMrrfreq, set_s_editMrrfreq] = useState(false);
  const [s_editMrrcycle, set_s_editMrrcycle] = useState(false);
  const [s_AllProduct, set_s_AllProduct] = useState([]);

  const onSubmit = async (values) => {
    const type = Object.keys(s_MouldData).length === 0 ? 'create' : 'modify';

    const tmpObj = {
      ...values,
      'moldcavity': parseInt(values.moldcavity),
      'moldtype': parseInt(values.moldtype)
    }

    console.log('要傳給後端的資料 = ', tmpObj)

    if(type === 'create') {
      const call_addMold = async () => {
        const res = await addMold(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        call_getAllMould()
      };
      call_addMold();
    }

    if(type === 'modify') {
      const call_modifyMold = async () => {
        const res = await modifyMold(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        call_getAllMould()
      };
      call_modifyMold();
    }

    set_s_showModal(false);
    
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

   // 取得全部產品基本資料(做料號下拉選單用)
   const call_getAllProduct = async () => {
    
    try {
      const res = await getAllProduct();
      if(res.data) {
        set_s_AllProduct(res.data);
        console.log('全部產品基本資料 = ', res.data)
        //message.success(res.msg);
      }
    } catch (e) {
      console.log("全部產品基本資料-資料獲取失敗", e);
      message.error('資料獲取失敗');
    } 
  };

  // first render 取得所有工站資料、料號, 並決定是否可編輯次數、週期
  useEffect(() => {
    const call_getAllStation = async () => {
      const res = await getAllStation();

      if(res.data) {
        console.log(res.data)
        set_s_AllStation(res.data);
      }
    };

    call_getAllStation();
    call_getAllProduct();

    if(Object.keys(s_MouldData).length > 0) {
      if(s_MouldData.setmrrfreq && s_MouldData.setmrrfreq === 'Y') {
        set_s_editMrrfreq(true)
      }
      if(s_MouldData.setmrrcycle && s_MouldData.setmrrcycle === 'Y') {
        set_s_editMrrcycle(true)
      }
    }
  }, [])

  // 卸載時更新畫面
  useEffect(() => {

    return () => {
      //alert('123')
      set_s_MouldData({})
    }
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [form, s_MouldData]);

  return (
    <>
      <Form.Provider
        
        onFormChange={(formName, info) => {
          const { changedFields } = info;
          // '是否設定保養次數' 選擇 '是',才可編輯
          if(changedFields[0] && changedFields[0].name[0] === 'setmrrfreq') {
            if(changedFields[0].value === 'N') {
              form.setFieldsValue({mrrfreq: ''});
              set_s_editMrrfreq(false);
            } else {
              set_s_editMrrfreq(true);
            }
          }
          // '是否設定保養週期' 選擇 '是',才可編輯
          if(changedFields[0] && changedFields[0].name[0] === 'setmrrcycle') {
            if(changedFields[0].value === 'N') {
              form.setFieldsValue({mrrcycle: '', mrrcycleUnit: null});
              set_s_editMrrcycle(false);
            } else {
              set_s_editMrrcycle(true);
            }
          }
        }}
      >
      <StyledForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            moldID: s_MouldData ? s_MouldData.moldID : null,
            PN: Object.keys(s_MouldData).length > 0 ? s_MouldData.PN : null, 
            wsID: Object.keys(s_MouldData).length > 0 ? s_MouldData.wsID : null, 
            moldtype: Object.keys(s_MouldData).length > 0 ? s_MouldData.moldtype : 1, 
            moldcavity: Object.keys(s_MouldData).length > 0 ? s_MouldData.moldcavity : 1, 
            setuptime: Object.keys(s_MouldData).length > 0 ? s_MouldData.setuptime : null, 
            setmrrfreq: Object.keys(s_MouldData).length > 0 ? s_MouldData.setmrrfreq : 'N',   // 模具保養依據(次數)
            mrrfreq: Object.keys(s_MouldData).length > 0 ? s_MouldData.mrrfreq : '',         // 模保次數
            setmrrcycle: Object.keys(s_MouldData).length > 0 ? s_MouldData.setmrrcycle : 'N', // 模具保養依據(週期)
            mrrcycleUnit: Object.keys(s_MouldData).length > 0 ? s_MouldData.mrrcycleUnit : null,       // 模具保養週期
            mrrcycle: Object.keys(s_MouldData).length > 0 ? s_MouldData.mrrcycle : '',       // 模具保養週期
          }}
        >
          <Row style={{marginBottom: '12px', display: 'none'}}>
            <Col span={8}>
              <Row>
                <Col offset={2}>
                  <Button disabled size='large' style={{borderRadius: '5px'}} onClick={() => alert('施工中')}>
                    <LeftOutlined />
                    上一筆
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col offset={8} span={8}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button size='large' style={{borderRadius: '5px', marginRight: '30px'}} onClick={() => alert('施工中')}>
                  下一筆
                  <RightOutlined />
                </Button>
              </div>

            </Col>
          </Row>

          <Row>
            <Col span={8} >
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>模具編號</label>}
                name="moldID"
                rules={[{ required: true, message: '請填寫模具編號!' }]}
              >
                <Input
                  name='moldID' 
                  placeholder="請填寫模具編號" 
                  disabled={Object.keys(s_MouldData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>料號</label>}
                rules={[{ required: true, message: '請選擇料號!' }]}
                name="PN"
              >
                <Select
                  disabled={Object.keys(s_MouldData).length > 0 ? true : false}
                  placeholder="請選擇料號"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {s_AllProduct && s_AllProduct.map((item, index) => {
                    return <Select.Option key={index} value={item.PN}>{item.PN}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>使用工站</label>}
                rules={[{ required: true, message: '請選擇工站!' }]}
                name="wsID"
              >
                <Select 
                  //disabled={Object.keys(s_MouldData).length === 0 ? false : true}
                  placeholder="請選擇工站" 
                  allowClear
                  name="wsID"
                  disabled={Object.keys(s_MouldData).length > 0 ? true : false}
                >
                  {s_AllStation.length > 0 && s_AllStation.map(item => {
                    return <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
          </Row>

          <Row>
            <Col span={8} >
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>穴數</label>}
                rules={[{ required: true, message: '請輸入穴數!' }]}
                name="moldcavity"
              >
                <Input name='moldcavity' placeholder="請填寫模具穴數" />
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>共模設定</label>}
                rules={[{ required: true, message: '請輸入共模設定!' }]}
                name="moldtype"
              >
                {/* <Input name='moldtype' placeholder="請填寫共模設定" /> */}
                <Select 
                  placeholder="請選擇共模設定" 
                  allowClear
                  name="moldtype"
                >
                  <Select.Option value={1}>單料模具</Select.Option>
                  <Select.Option value={2}>雙料模具</Select.Option>
                  <Select.Option value={3}>三料模具</Select.Option>
                  <Select.Option value={4}>四料模具</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>上模時間(分)</label>}
                name="setuptime"
              >
                {/* <DatePicker name="setuptime" placeholder='請選擇生產備機時間' style={{width: '100%'}} /> */}
                <InputNumber name='setuptime' style={{width: '100%'}} placeholder="請填寫生上模時間" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>模保頻率設定</label>}
                name="setmrrfreq"
              >
                <Radio.Group name="setmrrfreq">
                  <Radio value='Y'>是</Radio>
                  <Radio value='N'>否</Radio>
                </Radio.Group>
              </Form.Item> 
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>模保次數</label>}
                name="mrrfreq"
                rules={s_editMrrfreq === true ? [{ required: true, message: '請輸入保養次數!' }] : null}
              >
                <InputNumber
                  name='mrrfreq' 
                  style={{width: '100%'}}
                  // placeholder="請填寫保養次數" 
                  disabled={ s_editMrrfreq === false ? true : false}
                />
              </Form.Item> 
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>模保週期設定</label>}
                name="setmrrcycle"
              >
                <Radio.Group name="setmrrcycle">
                  <Radio value='Y'>是</Radio>
                  <Radio value='N'>否</Radio>
                </Radio.Group>
              </Form.Item> 
            </Col>
            <Col span={8} >
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>週期單位</label>}
                rules={s_editMrrcycle === true ? [{ required: true, message: '請選擇週期單位!' }] : null}
                disabled={ s_editMrrcycle === false ? true : false}
                name="mrrcycleUnit"
              >
                <Select 
                  placeholder="請選擇週期" 
                  allowClear
                  name="mrrcycleUnit"
                  disabled={ s_editMrrcycle === false ? true : false}
                >
                  <Select.Option value='W'>週</Select.Option>
                  <Select.Option value='M'>月</Select.Option>
                  <Select.Option value='S'>季</Select.Option>
                  <Select.Option value='Y'>年</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>模具保養週期</label>}
                name="mrrcycle"
                rules={s_editMrrcycle === true ? [{ required: true, message: '請輸入保養週期!' }] : null}
                disabled={ s_editMrrcycle === false ? true : false}
              >
                <InputNumber
                  name='mrrcycle' 
                  style={{width: '100%'}}
                  // placeholder="請填寫保養週期" 
                  disabled={ s_editMrrcycle === false ? true : false}
                />
              </Form.Item> 
            </Col>
          </Row>

          <Divider style={{margin: '6px 0px 12px 0px'}} />

          <Row>
            <Col offset={12} span={12}>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{marginRight: '30px', borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>

        </StyledForm>
        </Form.Provider>
    </>
  )

}
export default ModalContainer;