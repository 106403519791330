import React from 'react';
import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';
// import { fontSize } from '../../styled/publicTag';

import { DesktopOutlined } from '@ant-design/icons';

// const fontSize = {
//   h1: "78px",
//   h2: "76px",
//   h3: "74px",
//   h4: "72px",
//   p: "70px",
//   text: "68px",
// };
const screenWidth = window.screen.width;
console.log('screenWidth = ', screenWidth)
const fontSize = {
  h1: `${screenWidth > 1920 ? "78px" : screenWidth > 1550 ? "36px" : "30px"}`,
  h2: `${screenWidth > 1920 ? "75px" : screenWidth > 1550 ? "33px" : "27px"}`,
  h3: `${screenWidth > 1920 ? "72px" : screenWidth > 1550 ? "29px" : "24px"}`,
  h4: `${screenWidth > 1920 ? "69px" : screenWidth > 1550 ? "26px" : "21px"}`,
  p: `${screenWidth > 1920 ? "78px" : screenWidth > 1550 ? "20px" : "19px"}`,
  text: `${screenWidth > 1920 ? "78px" : screenWidth > 1550 ? "18px" : "17px"}`,
};

const innerWidth = window.innerWidth;

const Wrapper = styled.div`
  width: 100%;
  //padding: 20px;
  border-radius: 20px;
  border-top: 1px solid #f5f5f5;
  box-shadow: 0px 0px 3px 3px #00000029;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.cardBg};
  position: relative;

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //color: ${({ situF }) => situF === 'W' ? '#000' : '#FFF'};
    //font-size: 20px;
  }

  // 工線旗標
  .wsFlag {
    //background-Color: ${({ theme }) => theme.mainColor};
    /* font-size: ${fontSize.h4}; */
    font-size: ${fontSize.p};
    background-color: ${({ footerBg }) => footerBg};
    color: ${({theme}) => theme.text2};
    border-radius: 5px;
    //padding: 15px 25px 15px 25px;
    //padding: 5px 10px 5px 10px;
    padding: 0px 5px;
    //width: 25%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  // Header 機型的位置
  .Header > span {
    color: ${({ theme }) => theme.Color3};
    /* font-weight: bold; */
    font-size: ${fontSize.p};
  }

  .Header > .HeaderText {
    color: ${({ theme }) => theme.Color3};
  }

  .container {
    /* margin-top: 10px; */
    display: flex;
    flex-direction: column;
    font-family: Microsoft JhengHei;
  }

  .title {
    color: ${({theme}) => theme.Color5}; //
    font-weight: bold; 
    font-size: ${fontSize.text};
  }

  .value {
    color: ${({ theme }) => theme.mainColor};
    font-weight: bold;
    font-size: ${fontSize.text};
    white-space: nowrap; //强制文本在一行内输出
    overflow: hidden; //隐藏溢出部分
    text-overflow: ellipsis; //對溢出部分加上...
    /* -webkit-line-clamp: 1; */
    /* -webkit-box-orient: vertical; */
  }

  .dataInfo {
    display: flex;
    justify-content: space-between;
  }

  .data {
    display: flex;
    flex-direction: ${screenWidth > 1440 ? 'row' : 'column'};
    //justify-content: flex-start;
    font-size: ${fontSize.text};
    align-items: center;
  }

  .targetData {
    display: flex;
    /* flex-direction: ${screenWidth > 1440 ? 'row' : 'column'}; */
    flex-direction: column;
    font-size: ${fontSize.text};
    align-items: center;
  }

  .targetData > span:nth-child(1) {
    color: ${({theme}) => theme.Color5};
    font-weight: bold; 
    font-size: 14px;
    //font-size: ${fontSize.text};
  }

  .targetData > span:nth-child(2) {
    font-weight: bold;
    color: ${({ theme }) => theme.mainColor};
    font-size: ${fontSize.text};
    margin-left: 5px;
  }

  .footer {
    width: 100%;
    //height: 40px;
    background-Color: ${({ theme }) => theme.dashBoardCard};
    //background-color: ${({ footerBg }) => footerBg};
    padding-left: 20px;
    padding-right: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    font-size: ${fontSize.text};
  }

  .footerData {
    font-size: ${fontSize.text};
    padding: 0px;
  }
`
const Light = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${props => props.color ? props.color : null};
  border: 0.1px solid #E0E0E0;
  border-radius: 50%;
  vertical-align: middle;
`

const Card = ({data}) => {

  const { wlID, woN, PN, brand, model ,pdtNM, target, ngC, okC, inC, pdtRate, ngRate, situF } = data;

  const showColor = (situF) => {
    const colorMap = { 
      "W": 'rgba(255, 255, 255, 1)', 
      "R": " rgba(255, 13, 13, 1)", 
      "G": " rgba(54, 230, 28, 1)", 
      "Y": "rgba(255, 196, 0, 1)",
      "B": "rgba(14, 124, 246, 1)"
    }
    return colorMap[situF]
  }

  return (
    <Wrapper situF={situF} footerBg={showColor(situF)}>
      <div style={{padding: '10px 10px 0px 10px'}}>
        <div className='Header'>
          <div className='wsFlag'>
            <DesktopOutlined style={{fontSize: '20px', marginRight: '15px'}} />
            <span className='HeaderText'>{wlID}</span>
          </div>
          {/* <Light color={showColor(situF)} /> */}
          <span>{`${brand} - ${model}`}</span>
        </div>
        <div className='container'>
          <Row>
            <Col span={4} className='title'>工單:</Col>
            <Col span={20} className='value'>{woN}</Col>
          </Row>
          {/* <Row>
            <Col span={4} className='title'>機型:</Col>
            <Col span={20} className='value'>{`${brand} - ${model}`}</Col>
          </Row> */}
          <Row>
            <Col span={4} className='title'>料號:</Col>
            <Col span={20} className='value'>{PN}</Col>
          </Row>
          <Row>
            <Col span={4} className='title'>品名:</Col>
            <Col span={20} className='value'>****</Col>
          </Row>
          <Divider style={{margin: '0px'}} />
          <div className='dataInfo'>
            <div className='targetData'>
              <span>目標產量</span>
              <span>{target.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </div>
            <div className='targetData'>
              <span>生產數量</span>
              <span>{inC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </div>
            <div className='targetData'>
              <span>良品數量</span>
              <span>{okC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </div>
            <div className='targetData'>
              <span>不良數量</span>
              <span>{ngC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </div>
            
          </div>
        </div>
      </div>
      <div className="footer">
        <div className='footerData'>
          <span>達成率 ： </span>
          <span>{pdtRate}</span>
        </div>
        <div className='footerData'>
          <span>不良率 ： </span>
          <span>{ngRate}</span>
        </div>
      </div>
    </Wrapper>
  )
};

export default Card;