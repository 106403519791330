
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { MySubtitleH4 } from '../../../styled/publicTag';

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';
import CustomTabs from '../../../components/antdCustom/CustomTabs';
import CustomModal from '../../../components/antdCustom/CustomModal';

import { Tooltip, Button, message, Modal } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import {getAllPlanInHd} from '../../../services/WMS/PlainIn';

import Header from './Header';
import ModalContainer from './ModalContainer';
import TB from './TB';

import {IconCircle} from '../../../components/Icon/newicon';

const opcodeData = [
  {
    'key': '4110',
    'tabName': '生產入庫'
  },
  {
    'key': '4120',
    'tabName': '採購入庫'
  },
  {
    'key': '4130',
    'tabName': '其他入庫'
  }
];

const innerWidth = window.innerWidth;

const PlainIn = () => {

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [ is_openModal, set_is_openModal ] = useState(false); // 是否開啟編輯入庫循環的對話框

  const [ s_PlainInData, set_s_PlainInData ] = useState({});  // 要被編輯的入庫資料

  const [ s_DetailsData, set_s_DetailsData ] = useState({});  // 要被查看的異動明細

  const [ s_record, set_s_record ] = useState('');  // 表身亮起來的key

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_searchData, set_s_searchData] = useState({    // 要搜尋的資料
    'opcodeID': '4110',
    'startDate': moment(new Date()).format('YYYY-MM-DD'),
    'endDate': moment(new Date()).format('YYYY-MM-DD'),
  });

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const tableColumns = [
    {
      title: '作業單別',
      dataIndex: 'opcode',
      key: 'opcode',
      align: 'center',
      // width: innerWidth <= 1440 ? `${100}px` : `${120}px`
      render: (text) => {
        return opcodeData.find(item => item.key === text).tabName
      }
    },
    {
      title: '作業單號',
      dataIndex: 'jobN',
      key: 'jobN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${250}px`
    },
    {
      title: '異動歷程',
      dataIndex: 'lifeF',
      key: 'lifeF',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`,
      render: (text, rowData, index) => {
        const keyMap = {
          "0": '開單發佈',
          "1": '入庫規劃',
          "H": '料單入庫規劃',
          "4": "出庫規劃",
          "T": "已執行更新"
        };
        return keyMap[text]
      }
    },
    {
      title: '來源單號碼',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${300}px`
    },
    {
      title: '倉名',
      dataIndex: 'whNM',
      key: 'whNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`
    },
    {
      title: '作業時間',
      dataIndex: 'lifeFTstr',
      key: 'lifeFTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`
    },
    // {
    //   title: '異動明細',
    //   dataIndex: 'details',
    //   key: 'details',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    //   render: (text, rowData, index) => (
    //     <div style={{display: 'flex',justifyContent: 'center'}}>
    //       <Tooltip title="查看異動明細">
    //         <Button shape="circle" icon={<SearchOutlined />} onClick={() => alert('wait')} />
    //       </Tooltip>
    //     </div>
    //   )
    // },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center', fontSize: '30px'}}>
          <div style={{display: 'flex'}}>
            <IconCircle />
            {/* <IconPen className='icon' onClick={() => alert('wait edit api')} />
            <IconTrash className='icon' onClick={() => deletePlainIn(rowData)} />
            <IconSyncOutlined className='icon' onClick={() => alert('wait delete api')} /> */}
          </div>
        </div>
      )
    },
  ]

  // 刪除
  const deletePlainIn = (rowData) => {
    const keyMap = { "0": '開單發佈', "1": '入庫規劃', "H": '料單入庫規劃', "4": "出庫規劃", "T": "已執行更新" };

    if(rowData.lifeF !== '0') {
      message.warning(`僅可刪除開單發佈的入庫資料`);
    }
    if(rowData.lifeF === '0') {
      Modal.confirm({
        title: '入庫資料刪除確認',
        icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
        content: '請先確認要刪除的入庫資料無誤，再進行刪除',
        okText: '確定',
        onOk: async () => {
          alert('wait api')
          // const res = await deleteStaffPerm(rowData);
          // res.status ? message.success(res.msg) : message.error(res.msg);
          //刪除後要更新資料
          // call_getAllStaffPerm();
        },
        cancelText: '取消',
      });
    }
  };

  // 更改搜尋項目
  const handleChange = (type, value) => {
    
    let tmp = {}

    if(type === 'time') {
      tmp = { ...s_searchData, 'startDate': value[0], 'endDate': value[1] };
    } else {
      tmp = { ...s_searchData, [type]: value };
    }
    
    set_s_searchData(tmp);
  };
 
  // 新增
  const handleClick = () => {
    set_is_openModal(true)
  }

  // 第一次渲染跟更改搜尋資料時取得入庫單
  useEffect(() => {
    // 取得全部入庫資料
    const call_getAllPlanInHd = async () => {
      try {
        const res = await getAllPlanInHd(s_searchData);

        if (res.status === true) {
          console.log('res.data = ', res.data)
          set_s_tableData(res.data);
        } else {
          set_s_tableData([]);
        }
        console.log('全部入庫資料 = ', res)

      } catch (e) {
        set_s_tableData([]);
      }
    }
    call_getAllPlanInHd();
  }, [s_searchData, is_openModal])

  const opcodeNM = opcodeData.find(item => item.key === s_searchData.opcodeID).tabName;

  return (
    <>
      <Title title='入庫循環'>
        <Header s_searchData={s_searchData} handleChange={handleChange} handleClick={handleClick} />
      </Title>

      <div style={{display: 'flex',justifyContent: 'center', fontSize: '30px' ,color: 'green'}}>
      <IconCircle />
      </div>

      <CustomTabs 
        onChange={activeKey => handleChange('opcodeID', activeKey)}
        activeKey={s_searchData.opcodeID}
        TabsData={opcodeData}
      />

      {/* 表頭 */}
      <CustomTable 
        // bordered={true}
        loading={tableLoading}
        scroll={{ x: "max-content",y: window.innerHeight - 64 - 60 - 70 - 50 - 120 }}
        columns={tableColumns}
        dataSource={s_tableData.length > 0 ? s_tableData.map((item, index) => {
          return {...item, key: index}
        }) : []}
        rowClassName={(record) => {
          return record.jobN === s_record.jobN ? "clickRowStyle" : "";
        }}
        onRow={(record) => {
          return {
            onClick: () => record.jobN === s_record.jobN ? set_s_record({}) : set_s_record(record)
          }
        }}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      /> 

      {/* 表身 */} 
      {Object.keys(s_record).length > 0 ? (
        <TB s_record={s_record} />
        ) : null}  

      {is_openModal ? (
        <CustomModal 
          width={innerWidth > 1440 ? '80%' : '70%'} 
          // title={Object.keys(s_PlainInData).length > 0 ? `修改${opcodeNM}` : `新建${opcodeNM}`} 
          title={Object.keys(s_PlainInData).length > 0 ? `修改${opcodeNM}資料` : `新建${opcodeNM}資料`} 
          visible={is_openModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => {
            set_is_openModal(false);
            set_s_PlainInData({});
          }}
        >
          <ModalContainer 
            opcodeID={s_searchData.opcodeID}
            set_is_openModal={set_is_openModal} 
            s_PlainInData={s_PlainInData}
            set_s_PlainInData={set_s_PlainInData} 
          />
        </CustomModal>
      ) : null}

      
    </>
  )
};

export default PlainIn;
