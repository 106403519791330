import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import { MySubtitleH4 } from '../../styled/publicTag';
import { Button, Input, message } from 'antd';

import {  ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import { getOkItem, deleteOkItem } from '../../services/api';

import ModalContainer from './ModalContainer'

import Title from '../../components/Custom/Title';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';
import CustomTabs from '../../components/antdCustom/CustomTabs';

import GoodProject from './GoodProject';
import GoodProjectModal from './GoodProject/ModalContainer';

import BadProject from './BadProject';
import BadProjectModal from './BadProject/ModalContainer';

import AbnDir from './AbnDir';
import AbnDirModal from './AbnDir/ModalContainer';

import AbnItem from './AbnItem';
import AbnItemModal from './AbnItem/ModalContainer';

import StopProject from './StopProject';
import StopProjectModal from './StopProject/ModalContainer';

import OEMitem from './OEMitem';
import OEMitemModal from './OEMitem/ModalContainer';

import {
  IconTrash,
  IconPen
} from '../../components/Icon'

message.config({
  duration: 1.5
})

export const DataContext = React.createContext();

const FunctionProject = () => {

  const innerWidth = window.innerWidth;

  const [ c_searchValue, set_c_searchValue ] = useState('');
  const [ c_editData, set_c_editData ] = useState({});

  const [ c_showModal, set_c_showModal ] = useState(false);  // 是否開啟 dialog
  const [ s_TabKey, set_s_TabKey ] = useState('StopProject'); // 目前是在哪個Tab上
  const [ s_placeholder, set_s_placeholder ] = useState('');

  const TabsData = [
    {
      'key': 'StopProject',
      'tabName': '停工項目'
    },
    {
      'key': 'GoodProject',
      'tabName': '良品項目'
    },
    {
      'key': 'BadProject',
      'tabName': '不良品項目'
    },
    {
      'key': 'AbnDir',
      'tabName': '異常單位'
    },
    {
      'key': 'AbnItem',
      'tabName': '異常項目'
    },
    {
      'key': 'OEMitem',
      'tabName': '外包項目'
    }
  ]

  
  const placeholder_Map = {
    'StopProject': '停工項目',
    'GoodProject': '良品項目',
    'BadProject': '不良項目',
    'AbnDir': '異常單位',
    'AbnItem': '異常項目',
    'OEMitem': '外包項目'
  }

  // 更改 Tab 時更新 placeholder、Modal title
  useEffect(() => {
    const placeholder = placeholder_Map[s_TabKey];
    set_s_placeholder(placeholder)
  }, [s_TabKey]);

  // 更改 Tab 時清空搜尋資料
  useEffect(() => {
    set_c_searchValue('');
  }, [s_TabKey]);

  // 搜尋
  const onSearch = () => {
    alert('wait')
  }

  // 更改搜尋資料
  const handleChange = event => {
    const value = event.target.value;
    set_c_searchValue(value);
    console.log(value)
    if(value === '') {
      alert('wait')
    }
  }

  // 建立
  const Create = () => {
    set_c_showModal(true);
  }


  return (
    <>
      <DataContext.Provider 
        value={{
          c_searchValue,
          set_c_searchValue,
          c_editData,
          set_c_editData,
          c_showModal,
          set_c_showModal
        }}
      >
        <Title title='功能字串管理'>
          <div style={{display: 'flex'}}>
            {/* <Input.Search 
              className='Input'
              size='large' 
              enterButton
              onChange={handleChange}
              value={c_searchValue}
              placeholder={`請輸入欲查詢的${s_placeholder}資料`}
              onSearch={onSearch}
            />*/}
            <Button 
              onClick={Create} 
              type="primary" 
              size='large'
              style={{borderRadius: '5px'}} 
              icon={<PlusOutlined />} 
            >
              新增
            </Button> 
          </div>
        </Title>

        {/* 決定目前要顯示哪個page */}
        <CustomTabs 
          onChange={activeKey => set_s_TabKey(activeKey)}
          activeKey={s_TabKey}
          TabsData={TabsData}
        />

        {/* 根據 Tab 顯示 page */}
        {s_TabKey === 'GoodProject' ? (
          <GoodProject />
        ) : s_TabKey === 'BadProject' ? (
          <BadProject />
        ) : s_TabKey === 'AbnDir' ? (
          <AbnDir />
        ) : s_TabKey === 'AbnItem' ? (
          <AbnItem />
        ) : s_TabKey === 'OEMitem' ? (
          <OEMitem />
        ) : s_TabKey === 'StopProject' ? (
          <StopProject />
        ) : null}


        {c_showModal ? ( 
          <CustomModal 
            width='60%' 
            title={Object.keys(c_editData).length > 0 ? `修改${s_placeholder}資料` : `新建${s_placeholder}資料`} 
            visible={c_showModal} 
            maskClosable={false}
            destroyOnClose
            footer={null}
            forceRender
            getContainer={false}
            onCancel={() => set_c_showModal(false)}
            afterClose={() => {
              //set_s_LineData({})
              //call_getAllLine()
            }}
          >
            {s_TabKey === 'AbnDir' ? (
              <AbnDirModal />
            ) : s_TabKey === 'AbnItem' ? (
              <AbnItemModal />
            ) : s_TabKey === 'BadProject' ? (
              <BadProjectModal />
            ) : s_TabKey === 'GoodProject' ? (
              <GoodProjectModal />
            ) : s_TabKey === 'StopProject' ? (
              <StopProjectModal />
            ) : s_TabKey === 'OEMitem' ? (
              <OEMitemModal />
            ) : null}
          </CustomModal>
        ) : null}
      </DataContext.Provider>
    </>
  )
};

export default FunctionProject;