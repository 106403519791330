import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, message, Select, Radio, Divider } from 'antd';

import { addOEMsupp, modifyOEMsupp } from '../../../services/api';

message.config({
  duration: 1.5
});

const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({ set_is_showModal, s_editData, set_s_editData }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields()
  }, [form, s_editData]);

  const onSubmit = async values => {

    const type = Object.keys(s_editData).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      // 新增廠商資料
      const res = await addOEMsupp(values);
      console.log('res = ', res)
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_is_showModal(false);
    };

    if(type === 'modify') {
      // 修改廠商資料
      console.log('要傳給後端的資料 = ', values)
      const res = await modifyOEMsupp(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_is_showModal(false);
    };
  };

  const handleCancel = () => {
    set_is_showModal(false)
  };

  useEffect(() => {

    return () => set_s_editData({})

  }, []);

  return (
    <StyledForm
      onFinish={onSubmit}
      name="basic"
      form={form}
      autoComplete="off"
      layout="vertical"
      initialValues={{
        suppID: Object.keys(s_editData).length > 0 ? s_editData.suppID : '',    // 廠商ID
        suppNM: Object.keys(s_editData).length > 0 ? s_editData.suppNM : '',    // 廠商名稱
        supptype: Object.keys(s_editData).length > 0 ? s_editData.supptype : '',  // 供應類別
        // typeNM: Object.keys(s_editData).length > 0 ? s_editData.typeNM : '',      // 類別名稱
        note: Object.keys(s_editData).length > 0 ? s_editData.note : '',      // 備註
      }}
    >
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>廠商ID</label>}
            name="suppID"
            rules={[{ required: true, message: '請填寫廠商ID!' }]}
          >
              <Input disabled={Object.keys(s_editData).length === 0 ? false : true} name='suppID' placeholder="請填寫廠商ID" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>廠商名稱</label>}
            name="suppNM"
            rules={[{ required: true, message: '請填寫廠商名稱!' }]}
          >
              <Input name='suppNM' placeholder="請填寫廠商名稱" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>供應類別</label>}
            name="supptype"
            rules={[{ required: true, message: '請選擇供應類別!' }]}
          >
            <Select placeholder="請選擇供應類別" allowClear>
              <Select.Option value='oem'>委外加工</Select.Option>
              <Select.Option value='matl'>原料</Select.Option>
              <Select.Option value='part1'>自用零件</Select.Option>
              <Select.Option value='part2'>市售零件</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>供應類別</label>}
            name="supptype"

            rules={[{ required: true, message: '請填寫供應類別!' }]}
          >
              <Input name='supptype' placeholder="請填寫供應類別" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>類別名稱</label>}
            name="typeNM"
            rules={[{ required: true, message: '請填寫類別名稱!' }]}
          >
              <Input name='typeNM' placeholder="請填寫類別名稱" />
          </Form.Item>
        </Col> */}
      </Row>

      <Row>
        <Col span={24} >
          <Form.Item 
            label={<label style={{color: '#6C6C6C'}}>備註</label>}
            name="note"
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <Divider style={{margin: '6px 0px 24px 0px'}} />

      <Row>
        <Col span={24}>
          <div style={{display: 'flex',justifyContent: 'flex-end'}}>
            <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
              取消
            </Button>
            <Form.Item>
              <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                確定
              </Button>
            </Form.Item>
          </div>
        </Col>
        </Row>

    </StyledForm>
  )
};

export default ModalContainer;