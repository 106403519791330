import React from 'react';
import styled from 'styled-components';

import { Chart, LineAdvance, Point, Line, Legend, Tooltip, Axis} from 'bizcharts';

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px #00000029;
  padding: 10px;
`

const LineChart = ({width}) => {

  const data1 = [
    {
      time: "07:30",
      percent: 10,
    },
    {
      time: "08:00",
      percent: 0,
    },
    {
      time: "07:30",
      percent: 10,
    },
    {
      time: "07:30",
      percent: 10,
    },
    {
      time: "07:30",
      percent: 10,
    }
  ]
  // 数据源
  const data = [
    {
      month: "Jan",
      city: "Tokyo",
      time: "07:30",
      percent: 10,
      temperature: 7
    },
    {
      month: "Jan",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 15,
      temperature: 3.9
    },
    {
      month: "Feb",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 20,
      temperature: 6.9
    },
    {
      month: "Feb",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 40,
      temperature: 4.2
    },
    {
      month: "Mar",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 30,
      temperature: 9.5
    },
    {
      month: "Mar",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 40,
      temperature: 5.7
    },
    {
      month: "Apr",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 12,
      temperature: 14.5
    },
    {
      month: "Apr",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 8.5
    },
    {
      month: "May",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 18.4
    },
    {
      month: "May",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 11.9
    },
    {
      month: "Jun",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 21.5
    },
    {
      month: "Jun",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      temperature: 15.2
    },
    {
      month: "Jul",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 25.2
    },
    {
      month: "Jul",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 17
    },
    {
      month: "Aug",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 26.5
    },
    {
      month: "Aug",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 16.6
    },
    {
      month: "Sep",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 23.3
    },
    {
      month: "Sep",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      temperature: 14.2
    },
    {
      month: "Oct",
      city: "Tokyo",
      time: Date.parse('2016-11-25T13:30:00Z'),
      percent: 10,
      temperature: 18.3
    },
    {
      month: "Oct",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 10.3
    },
    {
      month: "Nov",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 13.9
    },
    {
      month: "Nov",
      city: "London",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 10,
      temperature: 6.6
    },
    {
      month: "Dec",
      city: "Tokyo",
      time: Date.parse('2016-11-25T12:30:00Z'),
      percent: 60,
      temperature: 9.6
    },
    {
      month: "Dec",
      city: "London",
      time: Date.parse('2016-11-25T12:50:00Z'),
      percent: 80,
      temperature: 4.8
    }
  ];
  const scale = {
    //temperature: { max: 100 },
    percent: { 
      min: 0, 
      max: 100,
      tickCount: 10,
      formatter: v => `${v}%`
      // type: 'linear',
      // ticks: ['10','20'] 
    },
    time: {
      //ticks: ['2016-11-25T12:30:00Z', '2016-11-25T13:00:00Z', '2016-11-25T13:30:00Z', '2016-11-25T14:00:00Z', '2016-11-25T14:30:00Z'],
      // type: "time",
      // //ticks: ['2016-11-25T12:30:00Z', '2016-11-25T13:00:00Z', '2016-11-25T13:30:00Z', '2016-11-25T14:00:00Z', '2016-11-25T14:30:00Z'],
      // ticks: ['2016-11-25T12:30:00Z', '2016-11-25T19:30:00Z'],
      // tickCount: 10,
      // mask: "HH:mm",
      //ticks: ['07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','21:30','22:00','22:30','23:00','23:30','00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30'] 
    }
    
    // time: {
    //   //type: 'time', // 会以数据的第一条记录自动判定，无特别需要可不配置。
    //   //nice: false,   // 是否自动调优，如果发现刻度较少，请关闭此项目， linear 和time 类型默认nice为false，其他默认为true
    //   //ticks: [... ],   // 如果配置了该项，则轴的刻度即显示数组中指定的刻度。
    //   //formatter: d => d, // 图表全局中该字段的数据进行格式化，影响包括轴label，tooltip，legend。
    //   //alias: '别名',     // 字段名的别名，影响显示在坐标轴、图例上的标题。
    //   //mask: 'HH:MM',   
    //   // type: 'linear',
    //   // mask: 'HH-MM',
    //   // // tickCount: 50,
    //   ticks: ['07:30', '08:00', '08:30', '09:00'],
    //   // tickCount: 4,
    //   //ticks: ['07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','21:30','22:00','22:30','23:00','23:30','00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30'] 
    // }
  }
  return (
    <Wrapper width={width}>
      <Chart scale={scale} padding={[30, 20, 100, 40]} autoFit data={data} interactions={['element-active']}>
        <Point position="time*percent" color="city" shape='circle' />
        <Line shape="smooth" position="time*percent" color="city" />
        <Tooltip shared showCrosshairs />
        <Axis 
          name="time"
          
          label={{
            rotate: -0.5,
          }}
        />
        <Legend 
          padding={[0, 0, 0, 0]}
          itemMarginBottom={100}
          background={{
            padding:[5,100,5,36],
            style: {
              fill: '#eaeaea',
              stroke: '#fff'
            }
          }} 
        />
      </Chart>
    </Wrapper>
  )
}

export default LineChart