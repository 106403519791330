import React, { useState, useEffect } from 'react';
//import styled  from 'styled-components';
import { MySubtitleH4, fontSize } from '../../styled/publicTag.js';
import { Modal, message, Button, Table } from 'antd';

import {
  IconTrash,
  IconPen
} from '../../components/Icon'

import { getProduct, getAllProduct, deleteProduct } from '../../services/api';
import { ExclamationCircleOutlined, DownloadOutlined, CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

import ModalContainer from './ModalContainer';
import FileModalContainer from './ModalContainer/FileModalContainer';
import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';

message.config({
  duration: 1.5
})

const BasicProductInfo = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]); // 全部產品基本資料
  
  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_showFileModal, set_s_showFileModal] = useState(false); // 是否開啟上傳用的 dialog

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [s_FileModalTitle, set_s_FileModalTitle] = useState(''); // 上傳用的 dialog title

  const [s_productData, set_s_productData] = useState({}); // 要被修改的產品基本資料

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const createProduct = () => {
    set_s_showModal(true);
  }

  // 修改員工基本資料
  const editProductData = productData => {
    set_s_showModal(true);
    set_s_productData(productData);
  }
  
  // 確認是否刪除產品基本資料
  const confirm = rowData => {
    Modal.confirm({
      title: '產品資料刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請確認您希望删除此產品資料',
      okText: '刪除',
      onOk: async () => {
        const tmpObj = {
          'PN': rowData.PN
        }
        const del_res = await deleteProduct(rowData);
        del_res.status === true ? message.success(del_res.msg) : message.error(del_res.msg);
        //刪除後要刷新資料
        // const add_res = await getAllProduct();
        // console.log(add_res.data)
        // set_s_tableData(add_res.data)
        call_getAllProduct();
      },
      cancelText: '取消',
    });
  }

  // 取得全部產品基本資料
  const call_getAllProduct = async () => {
    
    try {
      setTableLoading(true);
      console.log('s_searchValue = ', s_searchValue)
      const res = s_searchValue === '' ? await getAllProduct() : await getProduct(s_searchValue);
      if(res.status === true && res.data.length > 0) {
        set_s_tableData(res.data);
        console.log('全部產品基本資料 = ', res.data)
        //message.success(res.msg);
        
      }
      setTableLoading(false);
    } catch (e) {
      setTableLoading(false);

      console.log("全部產品基本資料-資料獲取失敗", e);
      message.error('資料獲取失敗');
    } 
  };
  
  // 取得全部產品基本資料，每次關閉 dialog 都重抓
  useEffect(() => {
   
    if(s_showModal === false) {
      call_getAllProduct();
      set_s_productData({});
    }
    
  }, [s_showModal])

  useEffect(() => {
    if(s_showFileModal === false) {
      call_getAllProduct();
    }
  }, [s_showFileModal])
  
  const tableColumns = [
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      // width: 200
    },
    {
      title: '品名',
      dataIndex: 'pdtNM',
      key: 'pdtNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${300}px` : `${400}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { textAlign: 'left' }
          },
          children: (
            <div>
              {text}
            </div>
          )
        };
      }
    },
    {
      title: '計量單位',
      dataIndex: 'unit',
      key: 'unit',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
      render: (text, rowData, index) => {
        const unitMap = {
          "0": "件", 
          "1": "公克(重量)",
          "2": "公斤(長度)", 
          "3": "公分(長度)",
          "4": "公尺(長度)",
          "5": "公升(容積)", 
          "6": "加侖(容積)"
        };
        return unitMap[rowData.unit]
      }
    },
    {
      title: '包裝單位',
      dataIndex: 'punit',
      key: 'punit',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
      render: (text, rowData, index) => {
        const unitMap = {
          "box": "箱", 
          "pkg": "包", 
          "bkt": "桶", 
          "roll": "捲", 
          "sli": "片", 
          "bag": "袋"
        };
        return unitMap[rowData.punit]
      }
    },
    {
      title: '機種',
      key: 'mtypeNM',
      dataIndex: 'mtypeNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: '料階',
      dataIndex: 'pdtClass',
      key: 'pdtClass',
      align: 'center',
      width: innerWidth <= 1440 ? `${120}px` : `${150}px`,
      render: (text, rowData, index) => {
        const pdtClassMap = {"0":"原料/耗材", "1":"零件(件)", "2":"半成品", "3": "成品"};
        return pdtClassMap[rowData.pdtClass]
      }
    },
    {
      title: '設定倉管',
      dataIndex: 'setStock',
      key: 'setStock',
      align: 'center',
      // width: 260,
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
      render: (text, rowData, index) => {
        const key = rowData.setStock;
        return {
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    // {
    //   title: 'SOP檔名',
    //   dataIndex: 'sopNM',
    //   key: 'sopNM',
    //   width: innerWidth <= 1440 ? `${180}px` : `${250}px`,
    //   align: 'center',
    //   render: (text, rowData, index) => {
    //     if(rowData.sopNM === '') {
    //       return (
    //         <Button 
    //           icon={<DownloadOutlined />}
    //           onClick={() => {
    //             set_s_FileModalTitle('sop');
    //             set_s_showFileModal(true);
    //             set_s_productData(rowData);
    //           }}
    //         >
    //           選擇SOP文件
    //         </Button>
    //       )
    //     } else {
    //       return (
    //         <span 
    //           className='fileLink'
    //           onClick={() => {
    //             set_s_FileModalTitle('sop');
    //             set_s_showFileModal(true);
    //             set_s_productData(rowData);
    //           }}
    //         >
    //           {rowData.sopNM}
    //         </span>
    //       )
    //     }
        
    //   }
    // },
    // {
    //   title: 'SIP檔名',
    //   dataIndex: 'sipNM',
    //   key: 'sipNM',
    //   width: innerWidth <= 1440 ? `${180}px` : `${250}px`,
    //   align: 'center',
    //   render: (text, rowData, index) => {
    //     if(rowData.sipNM === '') {
    //       return (
    //         <Button 
    //           icon={<DownloadOutlined />}
    //           onClick={() => {
    //             set_s_FileModalTitle('sip');
    //             set_s_showFileModal(true);
    //             set_s_productData(rowData);
    //           }}
    //         >
    //           選擇SIP文件
    //         </Button>
    //       )
    //     } else {
    //       return (
    //         <span 
    //           className='fileLink'
    //           onClick={() => {
    //             set_s_FileModalTitle('sip');
    //             set_s_showFileModal(true);
    //             set_s_productData(rowData);
    //           }}
    //         >
    //           {rowData.sipNM}
    //         </span>
    //       )
          
    //     }
    //   }
    // },
    {
      title: '更新時間',
      dataIndex: 'newupdtT',
      key: 'newupdtT',
      align: 'center',
      width: innerWidth <= 1440 ? `${130}px` : `${200}px`,
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <div style={{display: 'flex'}}>
            <IconPen onClick={() => editProductData(rowData)} className='icon' />
            <IconTrash onClick={() => confirm(rowData)} className='icon' /> 
          </div>
        </div>
      )
    }
  ]
  // 搜尋產品料號
  const onSearch = (value, event) => {
    
    const call_getProduct = async (value) => {
      
      if(s_searchValue === '') return;

      try {
        setTableLoading(true);
      
        const res = await getProduct(s_searchValue);
        
        if(res.status) {
          set_s_tableData(res.data);
          message.success(res.msg);
        } else {
          set_s_tableData([]);
          message.error(res.msg);
        }

        setTableLoading(false);

      } catch (e) {

        setTableLoading(false);

      }

      

    }
    call_getProduct(s_searchValue);
  }

  const handleChange = async value => {
    set_s_searchValue(value)
    if(value === '') {
      try {
        setTableLoading(true);
        const res = await getAllProduct();
        if(res.status === true && res.data.length > 0) {
          set_s_tableData(res.data);
          console.log('全部產品基本資料 = ', res.data)
          //message.success(res.msg);
          
        }
        setTableLoading(false);
      } catch (e) {
        setTableLoading(false);
  
        console.log("全部產品基本資料-資料獲取失敗", e);
        message.error('資料獲取失敗');
      } 
    }
  }

  return (
    <>
      <Title 
        title='產品基本資料'
        placeholder='請輸入欲查詢的產品料號'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createProduct}
      />
      <CustomTable 
        scroll={{ x: 'max-content',y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        loading={tableLoading}
        dataSource={s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {s_showModal ? (
        <CustomModal 
          width='60%' 
          title={ Object.keys(s_productData).length === 0 ? "新增產品基本資料" : "修改產品基本資料" } 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          afterClose={() => {
            set_s_productData({})
          }}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_productData={s_productData} />
        </CustomModal>
      ) : null}

      {s_showFileModal ? (
        <CustomModal 
          width='30%' 
          title={`${s_FileModalTitle.toUpperCase()}上傳`} 
          visible={s_showFileModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => {
            set_s_showFileModal(false);
            set_s_FileModalTitle('');
            set_s_productData({});
          }}
          afterClose={() => {
            set_s_showFileModal(false);
            set_s_FileModalTitle('');
            set_s_productData({});
          }}
        >
          <FileModalContainer 
            set_s_showFileModal={set_s_showFileModal} 
            s_productData={s_productData} 
            s_FileModalTitle={s_FileModalTitle}
            call_getAllProduct={call_getAllProduct}
          />
        </CustomModal>
      ) : null}
      
    </>
  )
}

export default BasicProductInfo;