import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, Select, Table, Divider, message, Radio } from 'antd';
import { PlusCircleFilled, MinusCircleFilled, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { getAllProduct, getAllStation, addWpcHd, modifyWpcHd } from '../../../services/api';

const CustomForm = styled(Form)`
  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .wpcWrapper {
    max-height: 210px;
    overflow: auto
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
`

const ModalContainer = ({ s_editData, set_s_editData, set_s_showModal }) => {

  const [ s_PNOptions, set_s_PNOptions ] = useState([]);
  const [ s_Station, set_s_Station ] = useState([]);

  // wpcSN: 途程號  nextSN: 次途程號  wsID: 站別
  const [s_wpc, set_s_wpc] = useState([
    {'wpcSN': '1', 'nextSN': 'T', 'wsID': 'WS01'},
  ]);

  const [form] = Form.useForm();

  const onSubmit = async values => {
    const type = Object.keys(s_editData).length > 0 ? 'edit' : 'create';
    // 轉字串，不然後端接不到
    const tmpWpc = Object.entries(s_wpc).map(([key, value]) => {
      return {
        'wpcSN': value.wpcSN.toString(),
        'nextSN': value.nextSN.toString(),
        'wsID': value.wsID.toString()
      }
    })

    let product = JSON.parse(JSON.stringify(values));
    product = {
      ...product,
      netwt: parseInt(product.netwt),
      pbom: parseInt(product.pbom)
    }
   
    
    if(type === 'create') {
      let tmpObj = {
        'HD': product,
        'TB': tmpWpc
      }
      console.log('送給後端的資料 = ', tmpObj)
      console.table(tmpObj.TB)
      const res = await addWpcHd(tmpObj);
      res.status === true ? message.success(res.msg) : message.error(res.msg)
      set_s_showModal(false)
    }
    
    if(type === 'edit') {
      // console.log('送給後端的資料 = ', product)
      const res = await modifyWpcHd({...product, wpcset: s_editData.wpcset});
      res.status === true ? message.success(res.msg) : message.error(res.msg)
      set_s_showModal(false)
    }
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  // 選擇站別
  const handleChange = (value, keyIndex) => {
    const tmpArray = s_wpc.map((item, index) => {
      if(keyIndex === index) {
        return {...item, 'wsID': value}
      } else {
        return item
      }
    })
    set_s_wpc(tmpArray)
  }

  // 增加、刪減途程
  const toggle = type => {
    let tmpArray = [...s_wpc];
    if(type === 'increase') {
      tmpArray.push({'wpcSN': 1, 'nextSN': 1});
      const newState = tmpArray.map((item, index) => {
        return {
          'wpcSN': index+1, 
          'nextSN': tmpArray.length === index+1 ? 'T' : index+2, 
          'wsID': item.wsID ? item.wsID : 'WS01'
        }
      })
      set_s_wpc(newState);
    }

    if(type === 'decrease') {
      if(tmpArray.length === 1) return;
      tmpArray.pop();
      const newState = tmpArray.map((item, index) => {
        return {
          'wpcSN': index+1, 
          'nextSN': tmpArray.length === index+1 ? 'T' : index+2, 
          'wsID': item.wsID ? item.wsID : 'WS01'
        }
      })
      set_s_wpc(newState)
    }
  }

  // 取得料號、工站下拉選單
  useEffect(() => {
    const call_getAllProduct = async () => {
      const res = await getAllProduct();
      if(res.status === true && res.data.length > 0) {
        set_s_PNOptions(res.data);
      }
    }

    // 取得工站資料
    const call_getAllStation = async () => {
      const res = await getAllStation();
      if(res.status === true) {
        set_s_Station(res.data)
        console.log(res.data)
      }
    }
    
    call_getAllProduct();
    call_getAllStation()
  }, []);

  // 增加、刪減途程後滾動條要出現在最下方
  useEffect(() => {
    let dom = document.querySelector('.wpcWrapper');
    if(dom) {
      dom.scrollTop = (s_wpc.length)*70;
    };
  }, [s_wpc]);

  useEffect(() => {
    form.resetFields()
  }, [form, s_editData]);

  // 卸載時清空資料
  useEffect(() => {
    return () => set_s_editData({});
  }, []);

  return (
    <>
      <Form.Provider>
        <CustomForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            PN: Object.keys(s_editData).length > 0 ? s_editData.PN : '',  // 料號
            wpcset: Object.keys(s_editData).length > 0 ? s_editData.wpcset : '',  // 途程組
            wpcsetNM: Object.keys(s_editData).length > 0 ? s_editData.wpcsetNM : '', // 料階
            isvalid: Object.keys(s_editData).length > 0 ? s_editData.isvalid : true,  // 是否有效
            note: Object.keys(s_editData).length > 0 ? s_editData.note : '',  // 途程組備註
          }}
        >
          <Row gutter={[36, 12]}>
            <Col span={12}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>料號</label>}
                name="PN"
                className='mb-12'
                rules={[{ required: true, message: '請選擇料號!' }]}
              >
                <Select 
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                  placeholder="請選擇料號" 
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {s_PNOptions.map((item, index) => {
                    return <Select.Option key={index} value={item.PN}>{item.PN}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>是否有效</label>}
                name="isvalid"
                className='mb-12'
                rules={[{ required: true, message: '請選擇是否有效!' }]}
              >
                <Radio.Group name="isvalid">
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{margin: '6px'}} />

          <Row gutter={[36, 12]}>
            <Col span={12} >
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>途程組說明</label>}
                className='mb-12'
                name="wpcsetNM"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col span={12} >
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>途程組備註</label>}
                className='mb-12'
                name="note"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          {Object.keys(s_editData).length === 0 ? (
            <>
              <Divider style={{margin: '6px'}} />
              <Row gutter={[36, 12]}>
                <Col span={8} style={{fontSize: '20px', margin: '10px 0px 10px 0px'}}>途程號設定</Col>
              </Row>
            </>
          ) : null}
          
          <div className='wpcWrapper'>
            {Object.keys(s_editData).length === 0 && s_wpc.map((item, index) => {
              return (
                <Row key={index} gutter={[24, 24]}>
                  <Col span={8} className="mb12">
                    <Form.Item 
                      // labelCol={{span:6}}
                      // wrapperCol={{span:20}} 
                      label={<label style={{color: '#6C6C6C'}}>途程號</label>}
                    >
                      <Input value={item.wpcSN} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={8} className="mb12">
                    <Form.Item 
                      // labelCol={{span:6}}
                      // wrapperCol={{span:20}} 
                      label={<label style={{color: '#6C6C6C'}}>次途程號</label>}
                      // name="PN"
                    >
                      <Input value={item.nextSN} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={8}>

                    <Row>
                      <Col span={18}>
                        <Form.Item 
                          label={<label style={{color: '#6C6C6C'}}>站別</label>}
                        >
                          <Select
                            onChange={(value, options) => handleChange(value, index)} 
                            disabled={Object.keys(s_editData).length > 0 ? true : false}
                            value={item.wsID} 
                            // style={{ width: Object.keys(s_productData).length === 0 ? '70%' : '100%' }}
                          >
                            {s_Station && s_Station.map((item, index) => {
                              return <Select.Option key={index} value={item.wsID}>{item.wsNM}</Select.Option>
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} flex="center">
                        {/* 新建產品基本資料且是最後一筆 */}
                        {Object.keys(s_editData).length === 0 && s_wpc.length === (index+1) ? (
                          <div style={{marginTop: '35px'}}>
                            <PlusCircleOutlined onClick={() => toggle('increase')} style={{fontSize: '20px', marginLeft: '20px'}} />
                            <MinusCircleOutlined onClick={() => toggle('decrease')} style={{fontSize: '20px', marginLeft: '5px'}} />
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    
                  </Col>
                </Row>
              )
            })}
          </div>
          
          <Divider style={{margin: '6px'}} />

          <Row className="mt-20">
            <Col span={8}>
              <Row style={{display: 'none'}}>
                {/* {Object.keys(s_staffData).length > 0 ? ( */}
                <Col offset={2}>
                  <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={() => alert('施工中')}>
                    據此創建新條目
                  </Button>
                </Col>
                {/* ) : null} */}
              </Row>
            </Col>
            <Col offset={8} span={8}>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{ borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
          
        </CustomForm>
      </Form.Provider>
    </>
  )
};

export default ModalContainer;