import React from 'react';
import styled from 'styled-components';
import { fontSize } from '../../styled/publicTag';
const Wrapper = styled.div`
  position: relative;
  width: 200px;
  height: 100px;
  overflow: hidden;
  //background-color: blue;

  .box1 {
    width: 200px;
    height: 100px;
    //background-color: #d22d46;
    //background: linear-gradient(rgb(235, 79, 115) , rgb(235, 79, 115));
    background-color: ${({bgColor}) => bgColor};
    box-shadow: inset 1px 1px 6px #00000029;
    border: 1px solid #c0c0c0;
    border-radius: 200px 200px 0 0;
    //transform: rotate(0deg);
    z-index: 10;
    position: absolute;
    //bottom: 0
  }
  .box2 {
    width: 200px;
    height: 100px;
    background-color: #F0F0F0;
    //border: 1px solid #c0c0c0;
    border-radius: 200px 200px 0 0;
    /* transform: rotate(0deg); */
    transform: rotate(${({deg}) => deg});
    z-index: 50;
    position: absolute;
    transform-origin: 100px 100px;
    //transform: rotate(120deg);
    
  }
  // 要調整紅色棒子的粗細請修改box3, height須為width的一半
  .box3 {
    width: 150px;
    height: 75px;
    background-color: #FFF;
    //border: 1px solid #c0c0c0;
    border-radius: 150px 150px 0 0;
    //transform: rotate(${({deg}) => deg});
    z-index: 99;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 25px;
    font-weight: 600;
  }
`

const OvalChart = ({ percentage }) => {

  // 一個百分比佔1.8%
  const deg = percentage * 1.8;

  const bgColor = percentage === 100 ? '#50C9A4' : percentage >= 70 ? '#F9B27B' : '#FC9191';
  //alert(deg)

  return (
    <Wrapper bgColor={bgColor} deg={`${deg}deg`}>
      {/* <div className='wrapperx'> */}
        <div className='box1'></div>
        <div className='box2'></div>
        <div className='box3'>
          76.5%
        </div> 
      {/* </div> */}
    </Wrapper>
  )
};

export default OvalChart;