import React from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, Select, Table, Divider, message, Radio, Collapse } from 'antd';

const CustomForm = styled(Form)`
  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
`

const ModalContainer = ({set_s_showModal}) => {

  const [form] = Form.useForm();
  
  const onSubmit = () => {
    alert('onSubmit')
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }
  return (
    <>
      <Form.Provider>
      <CustomForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{

        }}
      >
        <Row className="ml30">

        </Row>
        <Row className="ml30">
          
        </Row>
        <Row className="ml30">
          
        </Row>
        <Row className="ml30">
          <Col offset={16} span={8}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </CustomForm>
      </Form.Provider>
    </>
  )
};

export default ModalContainer;