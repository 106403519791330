import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';

import { addOEMItem, modifyOEMItem } from '../../../../services/api';

import { DataContext } from '../../index';

import { Form, Button, Input, Row, Col, Select, DatePicker, Radio, message, Divider, InputNumber } from 'antd';

const StyledForm = styled(Form)`

  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = () => {

  const { c_editData, set_c_editData, set_c_showModal } = useContext(DataContext);

  const [form] = Form.useForm();

  const onSubmit = async (values) => {
    const type = Object.keys(c_editData).length === 0 ? 'create' : 'modify';

    const tmpObj = {
      ...values,
      // 'moldcavity': parseInt(values.moldcavity),
      // 'moldtype': parseInt(values.moldtype)
    }

    console.log('要傳給後端的資料 = ', tmpObj)

    if(type === 'create') {
      const call_addOEMItem = async () => {
        const res = await addOEMItem(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        // call_getAllStopProject()
        set_c_showModal(false);
      };
      call_addOEMItem();
    }

    if(type === 'modify') {
      const call_modifyOEMItem= async () => {
        const res = await modifyOEMItem(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        // call_getAllStopProject()
        set_c_showModal(false);
      };
      call_modifyOEMItem();
    }
    
  }

  const handleCancel = () => {
    set_c_showModal(false)
  }

  // 卸載時更新畫面
  useEffect(() => {

    return () => {
      //alert('123')
      set_c_editData({})
    }
  }, []);

  useEffect(() => {
    form.resetFields()
  }, [form, c_editData]);

  return (
    <>
      <Form.Provider>
      <StyledForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            oemitem: c_editData ? c_editData.oemitem : '',  // 外包項目
            oemtype: Object.keys(c_editData).length > 0 ? c_editData.oemtype : null,  // 外包類別
            oemNM: Object.keys(c_editData).length > 0 ? c_editData.oemNM : '',  // 項目名稱
            isvalid: Object.keys(c_editData).length > 0 ? c_editData.isvalid : null,  // 是否有效
            note: Object.keys(c_editData).length > 0 ? c_editData.note : '', 
          }}
        >
          <Row gutter={[36, 12]}>
            <Col span={12} >
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>項目ID</label>}
                name="oemitem"
                rules={[{ required: true, message: '請填寫外包項目!' }]}
              >
                <Input
                  name='oemitem' 
                  placeholder="請填寫外包項目" 
                  disabled={Object.keys(c_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={12} >
            <Form.Item
                label={<label style={{color: '#6C6C6C'}}>外包類別</label>}
                name="oemtype"
                rules={[{ required: true, message: '請選擇外包類別!' }]}
              >
                <Select placeholder="請選擇外包類別" allowClear>
                  <Select.Option value='A'>人力</Select.Option>
                  <Select.Option value='B'>機工</Select.Option>
                  <Select.Option value='C'>表處</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            
          </Row>

          <Row gutter={[36, 12]}>
            <Col span={12} >
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>項目名稱</label>}
                name="oemNM"
                rules={[{ required: true, message: '請填寫項目名稱!' }]}
              >
                <Input
                  name='oemNM' 
                  placeholder="請填項目名稱" 
                  // disabled={Object.keys(c_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={12} >
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>是否有效</label>}
                rules={[{ required: true, message: '請填寫外包類別!' }]}
                name="isvalid"
              >
                <Radio.Group name="isvalid">
                  <Radio value='1'>是</Radio>
                  <Radio value='0'>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            
          </Row>

          <Row>
            <Col span={24} >
              <Form.Item 
                // labelCol={{offset:2}} 
                wrapperCol={{span:24}}
                label={<label style={{color: '#6C6C6C'}}>註記</label>}
                name="note"
              >
                <Input.TextArea 
                  rows={4} 
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{margin: '6px 0px 12px 0px'}} />

          <Row>
            <Col offset={12} span={12}>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{ borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>

        </StyledForm>
      </Form.Provider>
    </>
  )
};

export default ModalContainer;