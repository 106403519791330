import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';

import { MySubtitleH4 } from '../../../styled/publicTag';
import { message } from 'antd';

import { DataContext } from '../index';

import {  ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { getOEMItem, deleteOEMItem } from '../../../services/api';

import CustomTable from '../../../components/antdCustom/CustomTable';

import {
  IconTrash,
  IconPen
} from '../../../components/Icon';


message.config({
  duration: 1.5
})

const OEMitem = ({s_searchValue, s_showModal}) => {

  const innerWidth = window.innerWidth;
  const [s_tableData, set_s_tableData] = useState([]);

  const { c_showModal, set_c_showModal, set_c_editData, c_searchValue } = useContext(DataContext);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const tableColumns = [
    {
      title: '外包項目',
      dataIndex: 'oemitem',
      key: 'oemitem',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    },
    {
      title: '外包類別',
      dataIndex: 'oemtype',
      key: 'oemtype',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`
    },
    {
      title: '項目名稱',
      dataIndex: 'oemNM',
      key: 'oemNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${200}px`
    },
    {
      title: '是否有效',
      dataIndex: 'isvalid',
      key: 'isvalid',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            // style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {text === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : text === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '備註',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <div style={{display: 'flex'}}>
            <IconPen className='icon' onClick={() => editOemitem(rowData)} />
            <IconTrash className='icon' onClick={() => removeOemitem(rowData)} />
          </div>
        </div>
      )
    },
  ];

  const call_getAllOemitem = async () => {
    try {
      const res = s_searchValue === '' ? await getOEMItem() : await getOEMItem({'item':s_searchValue});
      console.log('res = ', res)
      if(res.status === true) {
        console.log('全部停工資料 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      } else {
        set_s_tableData([])
      }
      
    } catch (e) {
      console.log('---------- api getAllOemitem error ----------');
      console.log(e)
    }
  }

  // 編輯外包項目
  const editOemitem = rowData => {
    set_c_showModal(true);
    set_c_editData(rowData)
  }

  // 刪除外包項目
  const removeOemitem = rowData => {
    Modal.confirm({
      title: '外包項目資料刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的外包項目資料無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteOEMItem(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllOemitem();
      },
      cancelText: '取消',
    });
  }

  useEffect(() => { 
    if(c_showModal === false) {
      call_getAllOemitem();
    }
  }, [c_showModal]);

  return (
    <div>
      <CustomTable 
        scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
    </div>
  )
};

export default OEMitem