import React from 'react';
import styled from 'styled-components';
import { fontSize } from '../../styled/publicTag'
import {
  Chart,
  Tooltip,
  Interval,
  Effects,
  Legend,
  Guide,
  Axis,
} from "bizcharts";

const { Text } = Guide;

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px #00000029;
  padding: 10px;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: center;
  position: relative;
  //background-color: pink;

  .Header {
    font-size: ${fontSize.p};
    font-weight: 600;
    color: ${({ theme }) => theme.Color1};
  }

  .Footer {
    position: absolute;
    bottom: 0px;
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    //flex-direction: column;
    font-size: ${fontSize.h4};
    color: '#6F7C98';
    background-color: #F0F2F5;
    width: 100%;
  }
`

const WaveChart = ({width, height}) => {

  const data = [
    {
      gender: "male",
      value: 50,
      key: 1
    },
  ];
  
  const scale = {
    value: {
      min: 0,
      max: 100,
    },
  };

  return (
    <Wrapper width={width} height={height}>
      <div className='Header'>壓鑄工站開機率</div>
      <Chart data={data} width={350} height={250} scale={scale}>
        <Tooltip />
        <Axis visible={false} />
        <Interval
          position="gender*value"
          color="gender"
          shape="liquid-fill-gauge"
          style={{
            lineWidth: 10,
            fillOpacity: 0.75,
          }}
          size={300}
          customInfo={{}}
        />
        <Legend visible={false} />
        <Effects>
          {(chart) => {
            chart.geometries[0].customInfo({
              radius: 0.9,
              outline: { border: 2, distance: 0 },
              wave: { count: 3, length: 192 },
            });
          }}
        </Effects>
        <Guide>
          {data.map((row) => (
            <Text
              key={row.value}
              content={`${row.value}%`}
              top
              position={{
                gender: row.gender,
                value: 50,
              }}
              style={{
                opacity: 0.75,
                fontSize: window.innerWidth / 60,
                textAlign: "center",
              }}
            />
          ))}
        </Guide>
      </Chart>
      <div className='Footer'>
        <div style={{width: '50%', textAlign:'center'}}>總機數：100</div>
        <div style={{width: '1px', backgroundColor: '#C4C4C4'}} />
        <div style={{width: '50%', textAlign:'center'}}>開機數：100</div>
      </div>
    </Wrapper>
  )
}

export default WaveChart;