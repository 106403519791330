import React, { useState, useEffect, useRef, useContext } from 'react';

import { CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

import { MySubtitleH4 } from '../../../styled/publicTag.js';
import {
  IconTrash,
  IconPen
} from '../../../components/Icon';

import { DataContext } from '../index';

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';
import CustomModal from '../../../components/antdCustom/CustomModal';

import Header from './Header';
import ModalContainer from './ModalContainer/index';

import { getWpcTb } from '../../../services/api';

const innerWidth = window.innerWidth;

const TB = () => {

  let countRef = useRef(null);

  const [scrollY, setScrollY] = useState("")
  const [ s_showModal, set_s_showModal ] = useState(false);
  const [ s_editData, set_s_editData ] = useState({});

  const [ s_tableData, set_s_tableData ] = useState([]);

  const { c_lightData, set_c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 即時計算table高度
  const getTableScroll = ({ extraHeight, ref } = {}) => {
    if (typeof extraHeight == "undefined") {
      //  默认底部分页64 + 边距10
      extraHeight = 64
    }
    let tHeader = null
    if (ref && ref.current) {
      tHeader = ref.current.getElementsByClassName("ant-table-thead")[0]
    } else {
      tHeader = document.getElementsByClassName("ant-table-thead")[0]
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0
    if (tHeader) {
      tHeaderBottom = tHeader.getBoundingClientRect().bottom
    }
    // 窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    let tmpvh = Object.keys(c_lightData).length > 0 ? '50vh' : '100vh'

    let height = `calc(${tmpvh} - ${tHeaderBottom + extraHeight}px)`
    // 空数据的时候表格高度保持不变,暂无数据提示文本图片居中
    if (ref && ref.current) {
      let placeholder = ref.current.getElementsByClassName('ant-table-placeholder')[0]
      if (placeholder) {
        placeholder.style.height = height
        placeholder.style.display = "flex"
        placeholder.style.alignItems = "center"
        placeholder.style.justifyContent = "center"
      }
    }
    console.log('height = ', height)
    return height
  }

  const editWpc = () => {
    alert('wait')
  }

  const deleteWpc = () => {
    alert('wait')
  }

  // 關閉明細資料
  const handleClose = () => {
    set_c_lightData({});
  }

  const handleClick = () => {
    set_s_showModal(true)
  }

  useEffect(() => {

    const call_getWpcTb = async () => {
      const res = await getWpcTb({ PN: c_lightData.PN, wpcset: c_lightData.wpcset });

      console.log('TB res => ', res)

      res.status === true ? set_s_tableData(res.data) : set_s_tableData([]);
    }

    call_getWpcTb();

  }, [c_lightData, s_showModal]);

  const tableColumns = [
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
    },
    {
      title: '途程組',
      dataIndex: 'wpcset',
      key: 'wpcset',
      align: 'center',
      width: innerWidth <= 1440 ? `${130}px` : `${100}px`,
      
    },
    {
      title: '途程號',
      dataIndex: 'wpcSN',
      key: 'wpcsetNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { backgroundColor: '#f7e7af' }
          },
          children: text
        };
      }
    },
    {
      title: '次途程號',
      dataIndex: 'nextSN',
      key: 'nextSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { backgroundColor: '#f7e7af' }
          },
          children: text
        };
      }
    },
    {
      title: '站別',
      dataIndex: 'wsID',
      key: 'wsID',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { backgroundColor: '#f7e7af' }
          },
          children: text
        };
      }
    },
    {
      title: '產前標準單重',
      dataIndex: 'bfnetwt',
      key: 'bfnetwt',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: '產後標準單重',
      dataIndex: 'afnetwt',
      key: 'afnetwt',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: '途程備註',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${350}px`,
    },
    {
      title: '更新時間',
      dataIndex: 'updtTstr',
      key: 'updtTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    // {
    //   title: '操作',
    //   dataIndex: 'render',
    //   key: 'render',
    //   width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
    //   align: 'center',
    //   render: (text, rowData, index) => (
    //     <div style={{display: 'flex', justifyContent: 'space-around'}}>
    //       <div style={{display: 'flex'}}>
    //         <IconPen onClick={() => editWpc(rowData)} className='icon' />
    //         <IconTrash onClick={() => deleteWpc(rowData)} className='icon' /> 
    //       </div>
    //     </div>
    //   )
    // }
  ];

  const title = `${Object.keys(c_lightData).length > 0 ? `產品途程明細檔__${c_lightData.PN}_${c_lightData.wpcset}` : ''}`;

  return (
    <div>
      <Title title={title} >
        <Header handleClick={handleClick} handleClose={handleClose} />
      </Title>
      <CustomTable 
        size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
        scroll={{ x: 'max-content', y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {s_showModal ? (
        <CustomModal 
          width='90%' 
          title={ Object.keys(s_editData).length === 0 ? "新增產品途程明細" : "修改產品途程明細" } 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_tableData={s_tableData} set_s_editData={set_s_editData} />
        </CustomModal>
      ) : null}

    </div>
  )
};

export default TB;