import React from 'react';
import styled from 'styled-components';
import { fontSize } from '../../styled/publicTag';
const Wrapper = styled.div`
  //box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-shadow: inset 1px 1px 6px #00000029;
  height: 40px;
  width: 80%;
  border-radius: 5px;
  position: relative;
  border: 0.5px solid #C4C4C4;

  .box1 {
    width: 100%;
    height: 100%;
    //background-color: #F0F0F0;
    bottom: 0;
    z-index: 10;
    position: absolute;
    border-radius: 5px;
    //bottom: 0
  }

  .percentage {
    //color: #C0DCF0;
    color: ${({width}) => width > 60 ? '#FFF' : '#707070'};
    font-size: ${fontSize.p};
    border-radius: 5px;
    //text-shadow: -1px 0 #9e9e9e, 0 1px #9e9e9e, 1px 0 #9e9e9e, 0 -1px #9e9e9e;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 99;
  }

  .colorBar {
    width: 50%;
    height: 100%;
    border-radius: 5px;
    background-color:#FC9191;
    position: relative;
    z-index: 90
  }
`
const BgColor = styled.div`
  width: ${({width}) => width}%;
  height: 100%;
  //border-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: inset 1px 1px 6px #00000029;
  background-color:${({bgColor}) => bgColor};
  position: relative;
  z-index: 90;
`

const OvalChart = ({width}) => {

  const bgColor = width === 100 ? '#50C9A4' : width >= 70 ? '#F9B27B' : '#FC9191';

  return (
    <Wrapper width={width} >
      <div className='box1'></div>
      <BgColor width={width} bgColor={bgColor} />
      <div className='percentage'>{`${width} %`}</div>
    </Wrapper>
  )
};

export default OvalChart;

// const Wrapper = styled.div`
//   box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
//   height: 40px;
//   width: 80%;
//   border-radius: 5px;
//   position: relative;

//   .box1 {
//     width: 100%;
//     height: 100%;
//     background-color: #F0F0F0;
//     bottom: 0;
//     z-index: 10;
//     position: absolute;
//     border-radius: 5px;
//     //bottom: 0
//   }

//   .percentage {
//     color: #C0DCF0;
//     border-radius: 5px;
//     text-shadow: -1px 0 #9e9e9e, 0 1px #9e9e9e, 1px 0 #9e9e9e, 0 -1px #9e9e9e;
//     position: absolute;
//     right: 10px;
//     top: 50%;
//     transform: translateY(-50%);
//     z-index: 99;
//   }

//   .colorBar {
//     width: 50%;
//     height: 100%;
//     border-radius: 5px;
//     background: linear-gradient(rgb(96, 211, 149) 0%, rgb(245, 245, 245) 20%, rgb(96, 211, 149) 100%);
//     position: relative;
//     z-index: 90
//   }

// `

// const OvalChart = () => {

//   return (
//     <Wrapper>
//       <div className='box1'></div>
//       <div className='colorBar'></div>
//       <div className='percentage'>100%</div>
//     </Wrapper>
//   )
// };