import React, {useState, useEffect} from 'react';
import CustomTable from '../../../../components/antdCustom/CustomTable';
import {getAllPlanInTb} from '../../../../services/WMS/PlainIn';

const opcodeData = [
  {
    'key': '4110',
    'tabName': '生產入庫'
  },
  {
    'key': '4120',
    'tabName': '採購入庫'
  },
  {
    'key': '4130',
    'tabName': '其他入庫'
  }
];

const innerWidth = window.innerWidth;

const TB = ({s_record}) => {

  const [ s_tableData, set_s_tableData ] = useState([]);

  const tableColumns = [
    {
      title: '作業單號',
      dataIndex: 'opcode',
      key: 'opcode',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${250}px`,
      render: (text) => {
        return opcodeData.find(item => item.key === text).tabName
      }
    },
    {
      title: '動態數量',
      dataIndex: 'dqty',
      key: 'dqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${250}px`,
    },
    {
      title: '靜態數量',
      dataIndex: 'tqty',
      key: 'tqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${250}px`,
    },
    {
      title: '異動歷程',
      dataIndex: 'lifeF',
      key: 'lifeF',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`,
      render: (text, rowData, index) => {
        const keyMap = {
          "0": '開單發佈',
          "1": '入庫規劃',
          "H": '料單入庫規劃',
          "4": "出庫規劃",
          "T": "已執行更新"
        };
        return keyMap[text]
      }
    },
    {
      title: '作業時間',
      dataIndex: 'lifeFTstr',
      key: 'lifeFTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`
    },
  ]

  useEffect(() => {
    const call_getAllPlanInTb = async () => {
      const res = await getAllPlanInTb(s_record);
      console.log('res = ', res)

      res.status === true ? set_s_tableData(res.data) : set_s_tableData([])
    };
    call_getAllPlanInTb();
  }, []);

  return (
    <CustomTable 
      scroll={{ x: "max-content",y: window.innerHeight - 64 - 60 - 70 - 50 - 120 }}
      columns={tableColumns}
      dataSource={s_tableData.length > 0 ? s_tableData.map((item, index) => {
        return {...item, key: index}
      }) : []}
      pagination={false}
    /> 
  )
};

export default TB;