import React, {useState, useEffect} from 'react';

import moment from 'moment'

import { getAllStation } from '../../services/api';

import {  SearchOutlined } from '@ant-design/icons';
import { MySubtitleH4 } from '../../styled/publicTag.js';
import { Select, DatePicker, Button, Tabs } from 'antd';
import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomTabs from '../../components/antdCustom/CustomTabs';

const WorkPassStations = () => {

  const innerWidth = window.innerWidth;
  const Today = new Date();
  const date = `${Today.getFullYear()}-${Today.getMonth()+1}-${Today.getDate()}`;

  const [s_AllStation_options, set_s_AllStation_options] = useState([]); // 所有工站
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_searchData, set_s_searchData] = useState({
    'wsID': '',
    'tab': '',
    'startDate': date,
    'endDate': date,
  });

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10,
    page: 1
  });

  console.log(s_searchData)
  // 第一次渲染取得所有工站資料
  useEffect(() => {
    const call_getAllStation = async () => {
      try {
        const res = await getAllStation();
        if(res.data) {
          console.log('全部工站資料 = ', res.data)
          let options = res.data.map(item => <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>);
          options.splice(0, 0, <Select.Option key='index1' value=''>全部線別</Select.Option>);
          set_s_AllStation_options(options)
        }
      } catch (e) {
        console.log('---------- api getAllStaffPerm error ----------');
        console.log(e)
      }
    }
    call_getAllStation();
  }, []);

  // 有改變s_searchData就重新查詢
  useEffect(() => {
    alert('wait api')
  }, [s_searchData]);

  // 切換搜尋項目(工站、起始日期、結束日期)
  const handleChange = (type, value) => {
    const tmp = { ...s_searchData, [type]: value };
    set_s_searchData(tmp);
  }

  // 查詢
  // const searchWorkPassStations = () => {
  //   alert('wait api')
  // }

  // 切換標籤
  const changeTabs = activeKey => {
    alert(activeKey)
  }

  const TabsData = [
    {
      'key': 'aaa',
      'tabName': '過站途單'
    },
    {
      'key': 'bbb',
      'tabName': '轉MRB倉'
    },
    {
      'key': 'ccc',
      'tabName': '報廢料單'
    },
    {
      'key': 'ddd',
      'tabName': '入庫料單'
    }
  ]

  const tableColumns = [
    {
      title: '歷程時間',
      dataIndex: 'aaa',
      key: 'aaa',
      align: 'center',
      // width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '線別',
      dataIndex: 'wlID',
      key: 'wlID',
      //className: 'hidden',
      align: 'center',
      showHeader: false,
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '工單',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '品名',
      dataIndex: 'pdtNM',
      key: 'pdtNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '單別',
      dataIndex: 'bbb',
      key: 'bbb',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '途程號',
      dataIndex: 'ccc',
      key: 'ccc',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '計畫產量',
      dataIndex: 'ddd',
      key: 'ddd',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '前拆數量',
      dataIndex: 'eee',
      key: 'eee',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '投產數量',
      dataIndex: 'fff',
      key: 'fff',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '後拆數量',
      dataIndex: 'ggg',
      key: 'ggg',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '不良數量',
      dataIndex: 'hhh',
      key: 'hhh',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '產出數量',
      dataIndex: 'iii',
      key: 'iii',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '過站良率',
      dataIndex: 'jjj',
      key: 'jjj',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '計畫達成率',
      dataIndex: 'kkk',
      key: 'kkk',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '產出達成率',
      dataIndex: 'lll',
      key: 'lll',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '帳差平衡',
      dataIndex: 'mmm',
      key: 'mmm',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '途程組',
      dataIndex: 'nnn',
      key: 'nnn',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '插工碼',
      dataIndex: 'ooo',
      key: 'ooo',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '前拆碼',
      dataIndex: 'ppp',
      key: 'ppp',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '後拆碼',
      dataIndex: 'qqq',
      key: 'qqq',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '過站碼',
      dataIndex: 'rrr',
      key: 'rrr',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '單別',
      dataIndex: 'bbb',
      key: 'bbb',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '單別',
      dataIndex: 'bbb',
      key: 'bbb',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '單別',
      dataIndex: 'bbb',
      key: 'bbb',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '單別',
      dataIndex: 'bbb',
      key: 'bbb',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
  ]

  return (
    <>
      <Title title='工單過站查詢'>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Select 
            style={{marginRight: '15px', width: '150px'}} 
            size='large'
            value={s_searchData.wsID}
            onChange={value => handleChange('wsID', value)}
          >
            {s_AllStation_options}
          </Select>
          <DatePicker 
            size='large'
            allowClear={false}
            placeholder='請選擇起始日期'
            value={moment(s_searchData.startDate)}
            style={{marginRight: '15px', width: '180px'}}
            onChange={value => handleChange('startDate', value)} 
          />
          <DatePicker 
            size='large'
            allowClear={false}
            placeholder='請選擇結束日期'
            value={moment(s_searchData.endDate)}
            style={{width: '180px'}}
            onChange={value => handleChange('endDate', value)} 
          />
          {/* <Button onClick={searchWorkPassStations} type="primary" style={{borderRadius: '5px'}} icon={<SearchOutlined />} size='large'>
            查詢
          </Button> */}
        </div>
      </Title>
      <CustomTabs 
        onChange={activeKey => handleChange('tab', activeKey)}
        defaultActiveKey={TabsData[0].key}
        TabsData={TabsData}
      />

      <CustomTable 
        scroll={{ x: "max-content",y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData && s_tableData.length > 0 && s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              page: current,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      /> 
      
    </>
  )
};

export default WorkPassStations;