import React from "react";
import {
  Chart,
  Interval,
  Tooltip,
  Axis,
  Coordinate,
} from "bizcharts";

function LabellineChart({data}) {

  const colors = ["#71AFF8", "#9A87E9", "#F27FA2", "#58BEB3", "#E8B188"];

  const cols = {
    percent: {
      formatter: (val) => {
        return val + "%";
      },
    },
  };

  const getColer = (item) => {
    const colorIndex = data
      .map((x) => {
        return x.item;
      })
      .indexOf(item);
    return colorIndex;
  };

  return (
    <Chart height={430} data={data} scale={cols} autoFit >
      <Coordinate type="theta" radius={0.75} />
      <Tooltip
        showTitle={false}
        domStyles={{
          "g2-tooltip": {
            boxShadow: "none",
            color: "#fff",
            backgroundColor: "#222",
            height: "40px",
            width: "300px",
            fontSize: "25px",
          },
        }}
      />
      <Axis visible={false} />
      <Interval
        position="percent"
        adjust="stack"
        color={["itemNM", colors]}
        //
        label={[
          "itemNM",
          (item) => {
            return {
              offset: 20,
              content: (data) => {
                return `${data.itemNM}\n ${data.ngC}件`;
              },
              style: {
                fontSize: 25,
                fill: colors[getColer(item)],
              },
            };
          },
        ]}
      />
    </Chart>
  );
}

export default LabellineChart;
