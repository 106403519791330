import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

import { MySubtitleH4 } from '../../styled/publicTag';
import { message } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  IconTrash,
  IconPen
} from '../../components/Icon';

import Title from '../../components/Custom/Title';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';
import ModalContainer from './ModalContainer';

import { getAllPS, getAllWh, deleteWh, getWh } from '../../services/api';

message.config({
  duration: 1.5
})

const WareHouse = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);  // 是否開啟 dialog
  const [s_WhData, set_s_WhData] = useState({});

  const [ s_AllPSdata, set_s_AllPsdata ] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 建立新的倉別資料
  const createWh = () => {
    set_s_showModal(true);
  }

  // 搜尋倉別資料
  const onSearch = (value, event) => {
    
    const call_getWh = async (value) => {
      
      if(s_searchValue === '') return;
      // alert('wait api')
      const res = await getWh({whID:s_searchValue});
      
      if(res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        set_s_tableData([]);
        message.error(res.msg);
      }

    }
    call_getWh(s_searchValue);
  }

  //編輯倉別資料
  const editWh = rowData => {
    set_s_showModal(true);
    set_s_WhData(rowData);
  }

  // 刪除倉別資料
  const removeWh = rowData => {
    Modal.confirm({
      title: '倉別刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的倉別無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const tmpObj = {
          'whID': rowData.whID
        }
        const res = await deleteWh(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllWh();
      },
      cancelText: '取消',
    });
  }

  // 取得所有倉別資料
  const call_getAllWh = async () => {
    try {
      const res = s_searchValue === '' ? await getAllWh() : await getWh({whID:s_searchValue});
      // if (Wh_res.status) { set_s_tableData(Wh_res.data) };
      // const res = await getAllPS();
      console.log(res.data)
      const tmpData = res.data.map((item, index) => {
        return {
          ...item,
          key: index
        }
      });
      set_s_tableData(tmpData)
    } catch (e) {
      set_s_tableData([])
      console.log('---------- api getAllWh error ----------');
      console.log(e)
    }
  }

  // 第一次渲染取得全部車間資料
  useEffect(() => {
    const call_getAllPS = async () => {
      try {
        const res = await getAllPS();
        // console.log('getAllPS res = ', res);
        if (res.status) { set_s_AllPsdata(res.data) };

      } catch (e) {
        console.log('取得全部車間資料錯誤')
        set_s_AllPsdata([]);
      }
      
    }
    call_getAllPS()
  }, []);

  // 取得所有倉別資料
  useEffect(() => { 
    
    if(s_showModal === false) {
      call_getAllWh();
      set_s_WhData({})
    }
  }, [s_showModal]);

  // 關閉

  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value)
    if(value === '') {
      try {
        const res = await getAllWh();
        // if (Wh_res.status) { set_s_tableData(Wh_res.data) };
        // const res = await getAllPS();
        console.log(res.data)
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      } catch (e) {
        set_s_tableData([])
        console.log('---------- api getAllWh error ----------');
        console.log(e)
      }
    }
  }

  const tableColumns = [
    {
      title: '倉別',
      dataIndex: 'whID',
      key: 'whID',
      align: 'center',
      
    },
    {
      title: '倉名',
      dataIndex: 'whNM',
      key: 'whNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`
    },
    
    {
      title: '車間',
      dataIndex: 'psID',
      key: 'psID',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`
    },
    {
      title: '倉庫型態',
      dataIndex: 'whType',
      key: 'whType',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`
    },
    {
      title: '倉別註記',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${600}px`
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <IconPen className='icon' onClick={() => editWh(rowData)} />
          <IconTrash className='icon' onClick={() => removeWh(rowData)} />
        </div>
      )
    },
  ]

  return (
    <>
      <Title 
        title='倉別資料管理'
        placeholder='請輸入欲查詢倉別資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createWh}
      />
      <CustomTable 
        scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {s_showModal ? (
        <CustomModal 
          width='50%' 
          title={Object.keys(s_WhData).length > 0 ? "修改倉別資料管理" : "新建倉別資料管理"} 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          afterClose={() => {
            set_s_WhData({});
            // call_getAllPS();
          }}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_AllPSdata={s_AllPSdata} s_WhData={s_WhData} />
        </CustomModal>
       ) : null}
      
    </>
  )
};
export default WareHouse;