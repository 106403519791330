import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { fontSize, MySubtitleH4 } from '../../styled/publicTag.js';

import { getAllWpc, deleteWpc, getWpc } from '../../services/api';

import { PlusOutlined, ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons'

import {
  IconTrash,
  IconPen
} from '../../components/Icon'


import { Modal, Table, Input, Button, message } from 'antd';
import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';

//component
import ModalContainer from './ModalContainer';
import FileModalContainer from './ModalContainer/FileModalContainer';

message.config({
  duration: 1.5
})

const ProductWpc = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog
  const [s_WpcData, set_s_WpcData] = useState({});        // 要被編輯的途程資料

  const [s_FileModalTitle, set_s_FileModalTitle] = useState(''); // 上傳用的 dialog title
  const [s_showFileModal, set_s_showFileModal] = useState(false); // 是否開啟上傳用的 dialog

  const [ s_searchValue, set_s_searchValue ] = useState('')

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });
  
  const call_getAllWpc = async () => {
    setTableLoading(true);
    const res = s_searchValue === '' ? await getAllWpc() : await getWpc(s_searchValue);;
    if(res.status === true) {
      console.log('全部產品途程資料 = ', res.data)
      set_s_tableData(res.data)
      setTableLoading(false);
    }
  }

  // 取得全部途程資料，每次關閉 dialog 都重抓
  useEffect(() => {
    call_getAllWpc();
  }, [s_showModal]);

  // 新增產品途程資料
  const createWpc = () => {
    set_s_showModal(true);
  }

  // 修改產品途程資料
  const editWpc = rowData => {
    set_s_showModal(true);
    set_s_WpcData(rowData);
  }

  // 刪除產品途程資料
  const handelDelete = rowData => {
    Modal.confirm({
      title: '途程刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請確認您希望删除此途程',
      okText: '刪除',
      onOk: async () => {
        const res = await deleteWpc(rowData);
        console.log(rowData.PN, rowData.wsID)
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後重抓資料
        call_getAllWpc()
      },
      cancelText: '取消',
    });
    // const call_deleteWpc = async () => {
    //   const res = await deleteWpc(rowData.PN, rowData.wsID);
    //   console.log(rowData.PN, rowData.wsID)
    //   res.status ? message.success(res.msg) : message.error(res.msg);
    // };
    // call_deleteWpc();
  }

  const tableColumns = [
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      // width: 200
    },
    {
      title: '品名',
      dataIndex: 'pdtNM',
      key: 'pdtNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${300}px` : `${400}px`,
    },
    {
      title: '途程組',
      dataIndex: 'wpcset',
      key: 'wpcset',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${150}px`,
      render: (text, rowData, index) => rowData.wpcset && parseInt(rowData.wpcset) === 0 ? '標準途程' : rowData.wpcset
    },
    {
      title: '途程號',
      dataIndex: 'wpcSN',
      key: 'wpcSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: '站別',
      dataIndex: 'wsNM',
      key: 'wsNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${120}px` : `${150}px`,
    },
    {
      title: '次途程號',
      dataIndex: 'nextSN',
      key: 'nextSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${120}px` : `${150}px`,
    },
    {
      title: 'SOP檔名',
      dataIndex: 'SOP',
      key: 'SOP',
      width: innerWidth <= 1440 ? `${150}px` : `${200}px`,
      align: 'center',
      render: (text, rowData, index) => {
        if(rowData.sop === '') {
          return (
            <Button 
              icon={<DownloadOutlined />}
              onClick={() => {
                set_s_FileModalTitle('sop');
                set_s_showFileModal(true);
                set_s_WpcData(rowData);
              }}
            >
              選擇SOP文件
            </Button>
          )
        } else {
          return (
            <span 
              className='fileLink'
              onClick={() => {
                set_s_FileModalTitle('sop');
                set_s_showFileModal(true);
                set_s_WpcData(rowData);
              }}
            >
              {rowData.sop}
            </span>
          )
        }
        
      }
    },
    {
      title: 'SIP檔名',
      dataIndex: 'SIP',
      key: 'SIP',
      width: innerWidth <= 1440 ? `${150}px` : `${200}px`,
      align: 'center',
      render: (text, rowData, index) => {
        if(rowData.sip === '') {
          return (
            <Button 
              icon={<DownloadOutlined />}
              onClick={() => {
                set_s_FileModalTitle('sip');
                set_s_showFileModal(true);
                set_s_WpcData(rowData);
              }}
            >
              選擇SIP文件
            </Button>
          )
        } else {
          return (
            <span 
              className='fileLink'
              onClick={() => {
                set_s_FileModalTitle('sip');
                set_s_showFileModal(true);
                set_s_WpcData(rowData);
              }}
            >
              {rowData.sip}
            </span>
          )
        }
        
      }
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'space-around'}} > 
          <div style={{display: 'flex'}}>
            <IconPen className='icon' onClick={() => editWpc(rowData)} />
            <IconTrash className='icon' onClick={() => handelDelete(rowData)} />
          </div>
        </div>
      )
    },
  ]
  // 搜尋產品料號
  const onSearch = (value, event) => {
    
    const call_getWpc = async (value) => {
      
      if(value === '') return;
      
      const res = await getWpc(value);
      
      if(res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }

    }
    call_getWpc(value);
  }
  const handleChange = async value => {
    set_s_searchValue(value)
    console.log(value)
    if(value === '') {
      
      const res = await getAllWpc();
      if(res.status === true) {
        console.log('全部產品途程資料 = ', res.data)
        set_s_tableData(res.data)
      }
    }
  }
  // 取得所有產品塗層資料
  useEffect(() => {

  }, []);

  return (
    <>
      <Title 
        title='產品途程資料'
        placeholder='請輸入欲查詢的產品料號'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createWpc}
      />
      <CustomTable 
        // bordered={true} 'max-content'
        scroll={{ x: 'max-content',y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {/* {s_showModal ? (  */}
        <CustomModal 
          width='60%' 
          title={Object.keys(s_WpcData).length > 0 ? "修改產品途程資料" : "新建產品途程資料"} 
          visible={s_showModal} 
          loading={tableLoading}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          afterClose={() => {
            set_s_WpcData({});
            call_getAllWpc();
          }}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_WpcData={s_WpcData} set_s_WpcData={set_s_WpcData} />
        </CustomModal>
       {/* ) : null}   */}
      {s_showFileModal ? (
        <CustomModal 
          width='30%' 
          title={`${s_FileModalTitle.toUpperCase()}上傳`} 
          visible={s_showFileModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => {
            set_s_showFileModal(false);
            set_s_FileModalTitle('');
            set_s_WpcData({});
          }}
          afterClose={() => {
            set_s_showFileModal(false);
            set_s_FileModalTitle('');
            set_s_WpcData({});
          }}
        >
          <FileModalContainer 
            set_s_showFileModal={set_s_showFileModal} 
            s_WpcData={s_WpcData} 
            s_FileModalTitle={s_FileModalTitle}
            call_getAllWpc={call_getAllWpc}
          />
        </CustomModal>
      ) : null}
    </>
  )
}

export default ProductWpc;