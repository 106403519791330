import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { DataContext } from '../../index';

import { getAbnDri, addAbnItem, modifyAbnItem } from '../../../../services/api';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Button, Input, Row, Col, Select, DatePicker, Radio, message, Divider, InputNumber } from 'antd';

const StyledForm = styled(Form)`

  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const AbnItem = () => {

  const [form] = Form.useForm();

  const [s_AbnDir, set_s_AbnDir ] = useState([]);

  const { c_editData, set_c_editData, set_c_showModal } = useContext(DataContext);

  const onSubmit = async (values) => {
    const type = Object.keys(c_editData).length === 0 ? 'create' : 'modify';

    const tmpObj = {
      ...values
    }

    console.log('要傳給後端的資料 = ', tmpObj)

    if(type === 'create') {
      const call_addAbnItem = async () => {
        const res = await addAbnItem(tmpObj);
        console.log('res = ', res)
        res.status ? message.success(res.msg) : message.error(res.msg);
        //call_getAllBadProject()
        set_c_showModal(false);
      };
      call_addAbnItem();
    }

    if(type === 'modify') {
      const call_modifyAbnItem = async () => {
        const res = await modifyAbnItem(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //call_getAllBadProject()
        set_c_showModal(false);
      };
      call_modifyAbnItem();
    }
    
  }

  const handleCancel = () => {
    set_c_showModal(false)
  }

  // 取得所有異常單位資料
  const call_getAllAbnDir = async () => {
    try {
      const res = await getAbnDri();
      console.log('res = ', res)
      if(res.status === true) {
        console.log('全異常單位資料 = ', res.data);

        set_s_AbnDir(res.data)
      }
      
    } catch (e) {
      console.log('---------- api getNgItem error ----------');
      console.log(e)
    }
  }
  
  // 卸載時更新畫面
  useEffect(() => {

    call_getAllAbnDir();

    return () => {
      set_c_editData({})
    }
  }, []);

  useEffect(() => {
    form.resetFields()
  }, [form, c_editData]);

  return (
    <Form.Provider>
      <StyledForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            itemID: c_editData ? c_editData.itemID : null,
            itemNM: Object.keys(c_editData).length > 0 ? c_editData.itemNM : null, 
            dri: Object.keys(c_editData).length > 0 ? c_editData.dri : null, 
          }}
        >
          <Row style={{marginBottom: '12px', display: 'none'}}>
            <Col span={8}>
              <Row>
                <Col offset={2}>
                  <Button disabled size='large' style={{borderRadius: '5px'}} onClick={() => alert('施工中')}>
                    <LeftOutlined />
                    上一筆
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col offset={8} span={8}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button size='large' style={{borderRadius: '5px', marginRight: '30px'}} onClick={() => alert('施工中')}>
                  下一筆
                  <RightOutlined />
                </Button>
              </div>

            </Col>
          </Row>

          <Row>
            <Col span={8} >
              <Form.Item 
                // labelCol={{offset:2}} 
                wrapperCol={{span:22}}
                label={<label style={{color: '#6C6C6C'}}>項目ID</label>}
                name="itemID"
                rules={[{ required: true, message: '請填寫項目ID!' }]}
              >
                <Input
                  name='itemID' 
                  placeholder="請填寫項目ID" 
                  disabled={Object.keys(c_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:22, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>項目名稱</label>}
                rules={[{ required: true, message: '請填寫項目名稱!' }]}
                name="itemNM"
              >
                <Input
                  name='itemNM' 
                  placeholder="請填寫項目名稱" 
                  // disabled={Object.keys(c_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:22, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>負責單位</label>}
                rules={[{ required: true, message: '請選擇負責單位!' }]}
                name="dri"
              >
                <Select placeholder="請選擇負責單位" allowClear >
                  {s_AbnDir.length > 0 && s_AbnDir.map(item => {
                    return <Select.Option key={item.itemID} value={item.itemID}>{item.dri}</Select.Option>
                })}
                </Select>
              </Form.Item>
            </Col>
            
          </Row>

          <Row>
            <Col span={24} >
              <Form.Item 
                // labelCol={{offset:2}} 
                wrapperCol={{span:24}}
                label={<label style={{color: '#6C6C6C'}}>註記</label>}
                // rules={[{ required: true, message: '請填寫註記!' }]}
                name="note"
              >
                <Input.TextArea 
                  rows={4} 
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{margin: '6px 0px 12px 0px'}} />

          <Row>
            <Col offset={12} span={12}>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>

        </StyledForm>
        </Form.Provider>
  )
};

export default AbnItem;