import { useState, useEffect } from "react";
import { Chart, Tooltip, Legend, Point, Line, Axis,LineAdvance } from "bizcharts";
import moment from "moment";

const ProductionLineChart = ({chartData,infoData}) => {

  const [chartConfig,setChartConfig] = useState({
    range: [0,0,0,0,0]
  })
  //左=平均產速(inC/hr)，右=總產出數(inC)
  // let chartData = [];

  // for (let i = 0; i < 24; i++) {
  //   chartData.push({
  //     time: `${i}`,
  //     hourC: i * Math.floor(Math.random() * 10), //每時產出
  //     hourC: Math.floor(Math.random() * 100), //平均產速(inC/hr)
  //     inC: i * 24, //總產出數(inC)
  //   });
  // }
  const getDate = () => {
    
    const getTimeArray = (start, end) => {
      const quarterHours = ["00"];
      let times = [];
      for (let i = start; i <= end; i++) {
        if (i === end) {
          times.push(i + ":" + quarterHours[0]);
        } else {
          for (let j = 0; j < quarterHours.length; j++) {
            if(i < 10) {
              times.push("0"+i + ":" + quarterHours[j]);
            } else {
              times.push(i + ":" + quarterHours[j]);
            }
          }
        }
      }
      return times;
    }

    let morning = getTimeArray(8, 20);
    morning.pop();
    
    let night = getTimeArray(20, 24);
    night.pop();

    let newMorning = getTimeArray(0, 8);
    newMorning.pop();

    console.log(morning.concat(night).concat(newMorning).length)

    return morning.concat(night).concat(newMorning);
  };

  const timeArray = getDate();

  const new_chartData = chartData.length > 0 && chartData.map((item, index) => {
    const tmp = {
      ...item,
      newTime: timeArray[index]
    }
    return tmp
  })

  

  //console.log('chartData = ', chartData)

  useEffect(() => {
    if(Object.getOwnPropertyNames(infoData).length !== 0){
      let s = infoData.inC+""
      let i = parseInt(s.slice(0, 1))+1
      const temp = [0,0,0,0]
      // 計算出最大整數
      for (let l = 0; l < (infoData.inC+"").length-1 ; l++) {
        i = i+"0"
      }
      const tmp2 = temp.map((item, index) => {
        return  Math.floor(parseInt(i)/temp.length*(index+1))
      })
      tmp2.unshift(0)
      setChartConfig({...chartConfig, range:tmp2})
    }
    
  },[infoData])

  const scale = {
    //設定Y軸座標
    hourC: {
      range: [0, 1],
      ticks: chartConfig.range,
      tickCount:5,
      type: "linear",
    },
    inC:{
      type: 'linear',
      tickCount:5,
      ticks: chartConfig.range,
    }
  };
  
  let chartIns = null;

  const colors = ["#6394f9", "#62daaa"];

  return (
      <Chart
        autoFit
        height={430}
        data={new_chartData}
        scale={scale}
        onGetG2Instance={(chart) => {
          chartIns = chart;
        }}
      >
        <Axis
          name="hourC"
          label={{
            formatter: (val) => `${val}`,
            style: {fontSize: 16 }
          }}
        />
        <Axis
          name="newTime"
          label={{
            style: {fontSize: 16 },
            rotate: -1.2,
            offset: 30
          }}
        />
        <Legend
          custom={true}
          allowAllCanceled={true}
          items={[
            {
              value: "hourC",
              name: "平均產速",
              marker: {
                symbol: "circle",
                style: { fill: colors[0], r: 5 },
              },
            },
            {
              value: "inC",
              name: "總生產數",
              marker: {
                symbol: "circle",
                style: { fill: colors[1], r: 5 },
              },
            },
          ]}
          onChange={(ev) => {
            console.log("ev", ev);
            const item = ev.item;
            const value = item.value;
            const checked = !item.unchecked;
            const geoms = chartIns.geometries;

            for (let i = 0; i < geoms.length; i++) {
              const geom = geoms[i];

              if (geom.getYScale().field === value) {
                if (checked) {
                  geom.show();
                } else {
                  geom.hide();
                }
              }
            }
          }}
        />
        <Tooltip
          shared
          showCrosshairs
          domStyles={{
            "g2-tooltip": {
              boxShadow: "none",
              color: "#fff",
              backgroundColor: "#222",
              height: "90px",
              width: "300px",
              fontSize: "25px",
            },
          }}
          crosshairs={{
            type: "y",
          }}
          style={{
            color: "red",
          }}
        />
        <LineAdvance
          position="newTime*hourC"
          color={colors[0]}
          shape="smooth"
          size={4}
          area
          tooltip={[
            "newTime*hourC",
            (time, hourC) => {
              const myTitle = time
              return {
                name: "每時產出",
                value: `${hourC}`,
                title: myTitle,
              };
            },
          ]}
        />
        <Point
          position="newTime*hourC"
          color={colors[0]}
          size={3}
          shape="circle"
          tooltip={false}
        />
        <Line
          position="newTime*inC"
          color={colors[1]}
          size={4}
          shape="smooth"
          tooltip={[
            "newTime*inC",
            (time, inC) => {
              const myTitle = time;
              return {
                name: "總生產數",
                value: `${inC}`,
                title: myTitle,
              };
            },
          ]}
        />
        <Point
          position="newTime*inC"
          color={colors[1]}
          size={3}
          shape="circle"
          tooltip={false}
        />
      </Chart>
  );
};

export default ProductionLineChart;
