import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, Select, Table, Divider, message, Radio } from 'antd';
import { getAllProduct, getAllStation } from '../../../../services/api';

const CustomForm = styled(Form)`
  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .wpcWrapper {
    max-height: 210px;
    overflow: auto
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
`

const TbModalContainer = ({set_s_isShow, s_editData, set_s_editData, dataSource, set_dataSource}) => {

  const [ s_PNOptions, set_s_PNOptions ] = useState([]);
  const [ s_Station, set_s_Station ] = useState([]);
  const [form] = Form.useForm();

  // 取得料號、工站下拉選單
  useEffect(() => {
    const call_getAllProduct = async () => {
      const res = await getAllProduct();
      if(res.status === true && res.data.length > 0) {
        set_s_PNOptions(res.data);
      }
    }

    // 取得工站資料
    const call_getAllStation = async () => {
      const res = await getAllStation();
      if(res.status === true) {
        set_s_Station(res.data)
        console.log(res.data)
      }
    }
    
    call_getAllProduct();
    call_getAllStation();
  }, []);

  useEffect(() => {
    form.resetFields()
  }, [form, s_editData]);

  // 卸載時清空資料
  useEffect(() => {
    return () => set_s_editData({});
  }, []);

  const handleCancel = () => {
    set_s_isShow(false)
  }

  const onSubmit = values => {

    const type = Object.keys(s_editData).length > 0 ? 'edit' : 'create'

    if(type === 'edit') {
      const tmp = dataSource.map(item => {

        if(item.wpcset === values.wpcset && item.wpcSN === values.wpcSN && item.nextSN === values.nextSN) {
          return {
            ...item,
            wsID: values.wsID,
            bfnetwt: parseFloat(values.bfnetwt),
            afnetwt: parseFloat(values.afnetwt),
            wpcSN: (item.wpcSN).toString(),
            nextSN: (item.nextSN).toString(),
            note: values.note,
          }
        } else {
          return item
        }
  
      });
  
      set_dataSource(tmp);
      set_s_isShow(false);
    } else {

    }

    
  }

  return (
    <>
      <Form.Provider>
        <CustomForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            PN: Object.keys(s_editData).length > 0 ? s_editData.PN : '',  // 料號
            wpcset: Object.keys(s_editData).length > 0 ? s_editData.wpcset : '',  // 途程組
            wpcSN: Object.keys(s_editData).length > 0 ? s_editData.wpcSN : '',  // 途程號
            nextSN: Object.keys(s_editData).length > 0 ? s_editData.nextSN : '',  // 次途程號
            wsID: Object.keys(s_editData).length > 0 ? s_editData.wsID : '',  // 站別
            bfnetwt: Object.keys(s_editData).length > 0 ? s_editData.bfnetwt : '',  // 產前標準單重
            afnetwt: Object.keys(s_editData).length > 0 ? s_editData.afnetwt : '',  // 產後標準單重
            note: Object.keys(s_editData).length > 0 ? s_editData.note : '',  // 途程備註
          }}
        >
          <Row gutter={[36, 12]}>
            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>料號</label>}
                name="PN"
                className='mb-12'
                rules={[{ required: true, message: '請選擇料號!' }]}
              >
                <Select 
                  // disabled={Object.keys(s_editData).length > 0 ? true : false}
                  disabled={true}
                  placeholder="請選擇料號" 
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {s_PNOptions.map((item, index) => {
                    return <Select.Option key={index} value={item.PN}>{item.PN}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>站別</label>}
                name="wsID"
              >
                <Select
                  // disabled={Object.keys(s_editData).length > 0 ? true : false}
                  // style={{ width: Object.keys(s_productData).length === 0 ? '70%' : '100%' }}
                >
                  {s_Station && s_Station.map((item, index) => {
                    return <Select.Option key={index} value={item.wsID}>{item.wsNM}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[36, 12]}>
            <Col span={8}>
              <Form.Item  
                label={<label style={{color: '#6C6C6C'}}>產前標準單重</label>}
                name="bfnetwt"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item  
                label={<label style={{color: '#6C6C6C'}}>產後標準單重</label>}
                name="afnetwt"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[36, 12]}>
            <Col span={8} className="mb12">
              <Form.Item  
                label={<label style={{color: '#6C6C6C'}}>途程組</label>}
                name="wpcset"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8} className="mb12">
              <Form.Item  
                label={<label style={{color: '#6C6C6C'}}>途程號</label>}
                name="wpcSN"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8} className="mb12">
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>次途程號</label>}
                name="nextSN"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[36, 12]}>
            <Col span={24} >
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>途程組備註</label>}
                className='mb-12'
                name="note"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{margin: '6px'}} />

          <Row className="mt-20">
            
            <Col offset={16} span={8}>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{ borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>

        </CustomForm>
      </Form.Provider>
    </>
  )
};

export default TbModalContainer;