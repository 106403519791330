import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';

import { MySubtitleH4 } from '../../../styled/publicTag';
import { message } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DataContext } from '../index';
import { getAbnDri, getAbnItem, deleteAbnItem } from '../../../services/api';

import CustomTable from '../../../components/antdCustom/CustomTable';

import {
  IconTrash,
  IconPen
} from '../../../components/Icon'


message.config({
  duration: 1.5
})

const AbnItem = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_AbnDir, set_s_AbnDir ] = useState([]);

  const { c_showModal, set_c_showModal, set_c_editData, c_searchValue } = useContext(DataContext);
  
  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 取得所有異常項目資料
  const call_getAbnItem = async () => {
    try {
      const res = c_searchValue === '' ? await getAbnItem() : await getAbnItem({item: c_searchValue});
      console.log('res = ', res)
      if(res.data) {
        console.log('全部異常項目資料 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      }
      
    } catch (e) {
      console.log('---------- api getAbnItem error ----------');
      console.log(e)
    }
  }

  // 取得所有異常單位資料
  const call_getAllAbnDir = async () => {
    try {
      const res = await getAbnDri();
      console.log('res = ', res)
      if(res.status === true) {
        console.log('全異常單位資料 = ', res.data);

        set_s_AbnDir(res.data)
      }
      
    } catch (e) {
      console.log('---------- api getNgItem error ----------');
      console.log(e)
    }
  }

  console.log('s_AbnDir = ', s_AbnDir)

  // 刪除異常項目資料
  const removeAbnItem = rowData => {
    Modal.confirm({
      title: '異常項目資料刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的異常項目資料無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteAbnItem(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAbnItem();
      },
      cancelText: '取消',
    });
  }

  // 編輯異常項目資料
  const editAbnItem = rowData => {
    set_c_showModal(true);
    set_c_editData(rowData)
  }

  const tableColumns = [
    {
      title: '項目ID',
      dataIndex: 'itemID',
      key: 'itemID',
      align: 'center',
      // width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    },
    {
      title: '項目名稱',
      dataIndex: 'itemNM',
      key: 'itemNM',
      align: 'center',
      // width: innerWidth <= 1440 ? `${200}px` : `${250}px`
    },
    {
      title: '負責單位',
      dataIndex: 'dri',
      key: 'dri',
      align: 'center',
      // width: innerWidth <= 1440 ? `${200}px` : `${250}px`
      render: (text, rowData, index) => {
        return s_AbnDir.length > 0 ? (
          s_AbnDir.find(item => item.itemID === text).dri
        ) : null;
      }
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <div style={{display: 'flex'}}>
            <IconPen className='icon' onClick={() => editAbnItem(rowData)} />
            <IconTrash className='icon' onClick={() => removeAbnItem(rowData)} />
          </div>
        </div>
      )
    },
  ]

  // 取得所有異常單位
  useEffect(() => {
    call_getAllAbnDir()
  }, []);

  // 取得所有異常項目資料
  useEffect(() => { 
    if(c_showModal === false) {
      call_getAbnItem();
    }
  }, [c_showModal]);

  return (
    <div>
      <CustomTable 
        scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
    </div>
  )
};

export default AbnItem;