import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Chart, LineAdvance, Point, Line, Legend, Tooltip, Axis} from 'bizcharts';

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px #00000029;
  padding: 10px;
`

const LineChart = ({s_board, width, height}) => {

  const data1 = [
    {
      time: "07:30",
      percent: 10,
    },
    {
      time: "08:00",
      percent: 0,
    },
    {
      time: "07:30",
      percent: 10,
    },
    {
      time: "07:30",
      percent: 10,
    },
    {
      time: "07:30",
      percent: 10,
    }
  ]
  // 数据源
  const data = [
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "8:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "8:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "9:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "9:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "10:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "10:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "11:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "11:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "12:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "12:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "13:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "13:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "14:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "14:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "15:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "15:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "16:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "16:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "17:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "17:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "18:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "18:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "19:00"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "19:30"
    },
    {
        "ACT": 0,
        "situFT": "0001-01-01T00:00:00Z",
        "newsituFT": "",
        "time": "20:00"
    },
    
]
  //可配置样式
  const tickLine = {
    style: {
      lineWidth: 1, // 刻度线宽
      stroke: '#ccc', // 刻度线的颜色
    },
    length: 5, // 刻度线的长度, **原来的属性为 line**,可以通过将值设置为负数来改变其在轴上的方向

  }
  const getDate = () => {
    
    const getTimeArray = (start, end) => {
      const quarterHours = ["00", "30"];
      let times = [];
      for (let i = start; i <= end; i++) {
        if (i === end) {
          times.push(i + ":" + quarterHours[0]);
        } else {
          for (let j = 0; j < quarterHours.length; j++) {
            if(i < 10) {
              times.push("0"+i + ":" + quarterHours[j]);
            } else {
              times.push(i + ":" + quarterHours[j]);
            }
          }
        }
      }
      return times;
    }

    let morning = getTimeArray(8, 20);
    morning.pop();
    
    let night = getTimeArray(20, 24);
    night.pop();

    let newMorning = getTimeArray(0, 8);
    newMorning.pop();

    console.log(morning.concat(night).concat(newMorning).length)

    return morning.concat(night).concat(newMorning);
  };

  
  
  const scale = {
    
    time: {
      type: 'cat',
      
      values: getDate()
      // values: [
      //   '00:00', '00:30',
      //   '01:00', '01:30',
      //   '02:00', '02:30',
      //   '03:00', '03:30',
      //   '04:00', '04:30',
      //   '05:00', '05:30',
      //   '06:00', '06:30',
      //   '07:00', '07:30', 
      //   '08:00', '08:30', 
      //   '09:00', '09:30', 
      //   '10:00', '10:30', 
      //   '11:00', '11:30', 
      //   '12:00', '12:30', 
      //   '13:00', '13:30', 
      //   '14:00', '14:30', 
      //   '15:00', '15:30', 
      //   '16:00', '16:30', 
      //   '17:00', '17:30', 
      //   '18:00', '18:30', 
      //   '19:00', '19:30', 
      //   '20:00', '20:30', 
      //   '21:00', '21:30', 
      //   '22:00', '22:30', 
      //   '23:00', '23:30',
      //   '24:00'
      // ] 
    },
    ACT: {
      type: 'linear',
      min: 0,
      max: 100
    }
  }
  const timeArray = getDate();
  const new_s_board = s_board && s_board.map((item, index) => {
    const tmp = {
      ...item,
      time: timeArray[index]
    }
    return tmp
  })
  
  return (
    <Wrapper width={width} height={height}>
      <Chart scale={scale} padding={[30, 20, 100, 40]} autoFit data={new_s_board} interactions={['element-active']}>
        <Point 
          position="time*ACT" 
          // color="ACT" 
          shape='circle' 
          animate={false}
          // color={['ACT', (xVal) => {
          //   return 'rgb(80, 130, 246)';
          // }]} 
          // label={["ACT", { style: { fill: 'red' } }]}
        />
        <Line 
          shape="smooth" 
          position="time*ACT"  
          // color={['ACT', (xVal) => {
          //   return 'rgb(80, 130, 246)'
          // }]} 
        />
        {/* <Tooltip /> */}
        <Axis 
        name="time" 
        label={{
          rotate: -1.2,
          offset: 20
        }}
        />
        <Axis 
        name="ACT" 
        // label={{
        //   rotate: -1.2,
        //   offset: 20
        // }}
        />
        
        <Legend visible={false} />
        {/* <Legend 
          padding={[0, 0, 0, 0]}
          itemMarginBottom={100}
          background={{
            padding:[5,100,5,36],
            style: {
              fill: '#eaeaea',
              stroke: '#fff'
            }
          }} 
        /> */}
      </Chart>
    </Wrapper>
  )
}

export default LineChart