import React, { useState, useEffect } from 'react';
import { Select, Button, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

const Header = (props) => {

  const { s_searchData, s_AllStation, s_AllLine, handleChange, searchStations } = props;

  const [wlOptions, setwlOptions] = useState([]);

  // 全部工站資料
  const wsOptions = s_AllStation.map(item => {
    return <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>
  });

  // 工線資料
  //console.log('s_AllLine = ', s_AllLine.find(item => item.wsID === s_searchData.wsID))
  
  
  useEffect(() => {
    const Allwl = s_AllLine.filter(item => item.wsID === s_searchData.wsID);
    //console.log('Allwl = ', Allwl)
    if(Allwl) {
      let tmpwlOptions = Allwl.map(item => {
        return <Select.Option key={item.wlID} value={item.wlID}>{item.wlNM}</Select.Option>
      });

      tmpwlOptions.splice(0, 0, <Select.Option key='index' value=''>全部工線</Select.Option>);
      setwlOptions(tmpwlOptions)
    }
  }, [s_searchData])

  
  
  //console.log('wlOptions = ', wlOptions)

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <Select 
        size='large'
        style={{marginRight: '15px', width: '150px'}} 
        value={s_searchData.wsID}
        onChange={value => handleChange('wsID', value)}
      >
        {/*s_AllStation_options*/}
        {wsOptions}
      </Select>
      <Select 
        size='large'
        style={{marginRight: '15px', width: '150px'}} 
        value={s_searchData.wlID}
        onChange={value => handleChange('wlID', value)}
      >
        {wlOptions}
        
      </Select>
      <DatePicker.RangePicker 
        size='large'
        allowClear={false}
        value={[moment(s_searchData.startDate), moment(s_searchData.endDate)]}
        onChange={(dates, dateStrings) => {
          handleChange('time', dateStrings)
        }}
        // onCalendarChange={(dates, dateStrings, info) => {
        //   console.log('dateStrings = ', dateStrings)
        // }}
      />
      {/* <DatePicker 
        placeholder='請選擇起始日期'
        onChange={value => handleChange('startDate', moment(value).format('YYYY-MM-DD'))} 
        size='large'
        allowClear={false}
        style={{marginRight: '15px', width: '150px'}} 
        value={moment(s_searchData.startDate)}
      />
      <DatePicker 
        placeholder='請選擇結束日期'
        onChange={value => handleChange('endDate', moment(value).format('YYYY-MM-DD'))} 
        size='large'
        allowClear={false}
        style={{ width: '150px'}} 
        value={moment(s_searchData.endDate)}
      /> */}
      {/* <Button onClick={searchStations} type="primary" style={{borderRadius: '5px'}} icon={<SearchOutlined />} size='large'>
        查詢
      </Button> */}
    </div>
  );
};

export default Header;