import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import './ModalContainer.css'
import moment from 'moment'

import { addNgItem, modifyNgItem } from '../../../services/api';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { Form, Button, Input, Row, Col, Select, DatePicker, Radio, message, Divider, InputNumber } from 'antd';

const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`

  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_s_showModal, s_BadProjectData, set_s_BadProjectData, call_getAllBadProject}) => {

  const [form] = Form.useForm();


  const onSubmit = async (values) => {
    const type = Object.keys(s_BadProjectData).length === 0 ? 'create' : 'modify';

    const tmpObj = {
      ...values
    }

    console.log('要傳給後端的資料 = ', tmpObj)

    if(type === 'create') {
      const call_addNgItem = async () => {
        const res = await addNgItem(tmpObj);
        console.log('res = ', res)
        res.status ? message.success(res.msg) : message.error(res.msg);
        call_getAllBadProject()
      };
      call_addNgItem();
    }

    if(type === 'modify') {
      const call_modifyNgItem = async () => {
        const res = await modifyNgItem(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        call_getAllBadProject()
      };
      call_modifyNgItem();
    }

    set_s_showModal(false);
    
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  
  // 卸載時更新畫面
  useEffect(() => {

    return () => {
      //alert('123')
      set_s_BadProjectData({})
    }
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [form, s_BadProjectData]);

  return (
    <>
      <Form.Provider>
      <StyledForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            item: s_BadProjectData ? s_BadProjectData.item : null,
            itemNM: Object.keys(s_BadProjectData).length > 0 ? s_BadProjectData.itemNM : null, 
            note: Object.keys(s_BadProjectData).length > 0 ? s_BadProjectData.note : null, 
          }}
        >
          <Row style={{marginBottom: '12px', display: 'none'}}>
            <Col span={8}>
              <Row>
                <Col offset={2}>
                  <Button disabled size='large' style={{borderRadius: '5px'}} onClick={() => alert('施工中')}>
                    <LeftOutlined />
                    上一筆
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col offset={8} span={8}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button size='large' style={{borderRadius: '5px', marginRight: '30px'}} onClick={() => alert('施工中')}>
                  下一筆
                  <RightOutlined />
                </Button>
              </div>

            </Col>
          </Row>

          <Row>
            <Col span={12} >
              <Form.Item 
                // labelCol={{offset:2}} 
                wrapperCol={{span:22}}
                label={<label style={{color: '#6C6C6C'}}>項目ID</label>}
                name="item"
                rules={[{ required: true, message: '請填寫項目ID!' }]}
              >
                <Input
                  name='item' 
                  placeholder="請填寫項目ID" 
                  disabled={Object.keys(s_BadProjectData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={12} >
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:22, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>項目名稱</label>}
                rules={[{ required: true, message: '請填寫項目名稱!' }]}
                name="itemNM"
              >
                <Input
                  name='itemNM' 
                  placeholder="請填寫項目名稱" 
                  disabled={Object.keys(s_BadProjectData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>
            
          </Row>

          <Row>
            <Col span={24} >
              <Form.Item 
                // labelCol={{offset:2}} 
                wrapperCol={{span:24}}
                label={<label style={{color: '#6C6C6C'}}>註記</label>}
                rules={[{ required: true, message: '請填寫註記!' }]}
                name="note"
              >
                <Input.TextArea 
                  rows={4} 
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{margin: '6px 0px 12px 0px'}} />

          <Row>
            <Col offset={12} span={12}>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>

        </StyledForm>
        </Form.Provider>
    </>
  )

}
export default ModalContainer;